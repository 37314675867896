import React from "react";

const InputField = ({ label, value, onChange }) => {
  const inputId = label.toLowerCase().replace(" ", "-");

  return (
    <label
      htmlFor={inputId}
      className="relative block rounded-md border border-gray-200 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600"
    >
      <input
        type="text"
        id={inputId}
        className="peer block w-full px-3 py-2 text-sm border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0"
        placeholder={label}
        value={value}
        onChange={onChange}
      />
      <span
        className={`pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white px-1 text-sm text-gray-700 transition-all ${
          value ? 'top-0 text-xs' : 'peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs'
        }`}
      >
        {label}
      </span>
    </label>
  );
};

export default InputField;
