import React from "react";
import CustomTable from "./CustomTable";
import MatchTable from "./MatchTable";
import { getTableName, orderTables } from "./utils";

function GroupedTables({ groupKey, groupTables }) {
  const excludedKeys = ["coords", "Expected", "box_texttttttttt"];
  return (
    <div className="flex-1">
      <h1 className="text-2xl font-bold mb-4">
        {groupKey.replace(/_/g, " × ").toUpperCase()}
      </h1>
      {orderTables(groupTables).map(({ key, rows }) => {
        if (!Array.isArray(rows) || rows.length === 0) return null;
        const tableName = getTableName(key);
        const isMatchTable = [
          "Document Comparison",
          "Document Identification",
        ].some((part) => tableName.includes(part));
        const isDetailsTable = tableName.includes("Details");
        // const orderedKeys = rows.length > 0 ? Object.keys(rows[0]) : [];
        const orderedKeys = ["Unique Letter", "Item"];
        
        return (
          <div key={key} className="mb-6">
            <h2 className="text-xl font-bold mb-2">{tableName}</h2>
            {isMatchTable ? (
              <MatchTable rows={rows} tableName={tableName} />
            ) : (
              <CustomTable
                rows={rows}
                orderedKeys={orderedKeys}
                excludedKeys={excludedKeys}
                mergeEnabled={false}
                headerMappings={{
                  "Unique Letter": "#",
                }}
                formatNumbers={isDetailsTable}
              />
            )}
          </div>
        );
      })}
    </div>
  );
}

export default GroupedTables;
