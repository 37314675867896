import React, { useRef, useCallback, useMemo, useEffect } from "react";
import AgingSummaryDisplay from "./AgingSummaryDisplay";
import ConcentrationAnalysisDisplay from "./ConcentrationAnalysisDisplay";
import ContraAnalysisDisplay from "./ContraAnalysisDisplay";
import ImageModal from "./ImageModal";
import GroupedTables from "./GroupedTables";
import ImageGroup from "./ImageGroup";
import useModal from "./useModal";
import useReportData from "./useReportData";

function ReportComponent({ data, testType, sampleText, examID }) {
  const { isOpen, image, alt, openModal, closeModal } = useModal();
  const { reportData, tables } = useReportData(data, testType);
  const containerRef = useRef(null);

  const renderTablesAndImages = useCallback(
    (tables, images) => {
      if (typeof tables !== "object" || tables === null) {
        console.error("Invalid tables data!!!:", tables);
        return null;
      }
 

      const groupedTables = {};
      Object.entries(tables).forEach(([key, tableData]) => {
        if (!tableData || !tableData.topLevelKey) {
          console.error("Invalid tableData for key!!!:", key, tableData);
          return;
        }
        const { rows, topLevelKey } = tableData;
        if (!groupedTables[topLevelKey]) {
          groupedTables[topLevelKey] = [];
        }
        groupedTables[topLevelKey].push({ key, rows });
      });

      const sortedGroupedTables = Object.fromEntries(
        Object.entries(groupedTables).sort(([a], [b]) => a.localeCompare(b))
      );

      return Object.entries(sortedGroupedTables).map(
        ([groupKey, groupTables]) => (
          <div key={groupKey} className="flex mb-8">
            <GroupedTables groupKey={groupKey} groupTables={groupTables} />
            <ImageGroup
              images={images}
              groupKey={groupKey}
              onImageClick={openModal}
            />
          </div>
        )
      );
    },
    [openModal]
  );

  const renderContent = useCallback(() => {
    if (!reportData || Object.keys(reportData).length === 0) {
      return <p>No report data available for this test.</p>;
    }

    if (testType === "ar_ap_analysis") {
      console.log("reportDatareportData", reportData);
      const testName = reportData["test_name"];
      const testData = reportData["data"];
      console.log("TESTNAME", testName);
      console.log("testData", testData);
      console.log("sampleText", sampleText);
      if (testName !== sampleText) {
        return <p>Test name does not match sample text</p>;
      }
      if (sampleText === "AR Aging Summary") {
        console.log("summary data", testData);
        return <AgingSummaryDisplay data={testData} />;
      } else if (sampleText === "AR Concentration Analysis") {
        console.log("concentration data", testData);
        return (
          <ConcentrationAnalysisDisplay
            data={testData}
            examID={examID}
            testName={sampleText}
          />
        );
      } else if (sampleText === "AP Aging Summary") {
        console.log("summary data", testData);
        return <AgingSummaryDisplay data={testData} />;
      } else if (sampleText === "AP Concentration Analysis") {
        console.log("concentration data", testData);
        return <ConcentrationAnalysisDisplay data={testData} examID={examID} testName={sampleText} />;
      } else if (sampleText === "Contra Analysis") {
        console.log("contra data", testData);
        return <ContraAnalysisDisplay data={testData} examID={examID} />;
      }
    } else if (["invoice", "cash", "vendor", "gross_profit"].includes(testType)) {
      return renderTablesAndImages(tables, reportData.images);
    } else {
      return <p>Unsupported test type: {testType}</p>;
    }
  }, [testType, reportData, tables, renderTablesAndImages, sampleText]);

  const memoizedContent = useMemo(() => renderContent(), [renderContent]);

  return (
    <div ref={containerRef} className="container p-4">
      {memoizedContent}
      <ImageModal
        isOpen={isOpen}
        onClose={closeModal}
        imageSrc={image}
        altText={alt}
      />
    </div>
  );
}

export default ReportComponent;
