import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import getApiUrl from "./config";

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkAndRefreshToken = async () => {
      const access_token = localStorage.getItem("access_token");
      const refresh_token = localStorage.getItem("refresh_token");

      if (!access_token && refresh_token) {
        try {
          const response = await fetch(getApiUrl("/api/refresh_token"), {
            method: "POST",
            credentials: "include",
          });

          if (response.ok) {
            const data = await response.json();
            localStorage.setItem("access_token", data.access_token);
          } else {
            console.error("Failed to refresh token");
            navigate("/");
          }
        } catch (error) {
          console.error("Error refreshing token:", error);
          navigate("/");
        }
      }
    };

    checkAndRefreshToken();
  }, [navigate]);

  return children;
};

export default AuthProvider;
