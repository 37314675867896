// ImageWithScroll.js
import React, { useRef, useState, useEffect } from "react";

function ImageWithScroll({ imgData, subKey, title, onImageClick }) {
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [isScrollable, setIsScrollable] = useState(false);
  const ASPECT_RATIO = 4 / 3;

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const containerHeight = containerWidth * ASPECT_RATIO;

        setDimensions({
          width: containerWidth,
          height: containerHeight,
        });

        if (imgData === null) {
          setIsScrollable(false);
          return;
        }

        const img = new Image();
        img.onload = () => {
          const imageAspectRatio = img.naturalWidth / img.naturalHeight;
          setIsScrollable(imageAspectRatio < ASPECT_RATIO);
        };
        img.src = `data:image/png;base64,${imgData}`;
      }
    };

    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [imgData]);

  const handleImageClick = () => {
    if (imgData !== null) {
      onImageClick(`data:image/png;base64,${imgData}`, `${subKey}`);
    }
  };

  return (
    <div className="inline-block mx-2 mb-6">
      <h3 className="text-lg font-bold p-2">{title}</h3>
      <div
        ref={containerRef}
        className="w-[calc(50vw-375px)] min-w-[600px] max-w-[800px] border border-gray-500 rounded-lg overflow-hidden cursor-pointer"
        style={{
          height: `${dimensions.height}px`,
          aspectRatio: `${1 / ASPECT_RATIO}`,
        }}
        onClick={handleImageClick}
      >
        {imgData === null ? (
          <div className="w-full h-full flex items-center justify-center bg-gray-200">
            <span className="text-2xl font-bold text-gray-500">Loading...</span>
          </div>
        ) : (
          <div
            className={`w-full h-full ${
              isScrollable ? "overflow-y-auto" : "overflow-hidden"
            }`}
          >
            <img
              src={`data:image/png;base64,${imgData}`}
              alt={`${subKey}`}
              className="w-full h-auto object-contain"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ImageWithScroll;
