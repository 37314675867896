import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ExamItem from "./components/ExamItem";
import NewExamModal from "./components/NewExamModal";
import Header from "./components/Header";
import LoadingOverlay from "./components/LoadingOverlay";
import getApiUrl from "./config";

function DashboardPage() {
  console.log("DashboardPage.js");
  const navigate = useNavigate();
  const [exams, setExams] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    fetchUserInfo();
    fetchExams();
  }, []);

  useEffect(() => {
    console.log("userData updated:", userData);
    if (userData) {
      console.log("UserRole:", userData.UserRole);
    }
  }, [userData]);

  const fetchUserInfo = async () => {
    const url = getApiUrl("/api/get_user_info");
    console.log("Fetching user info from:", url);
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        console.log("User info received:", data);
        setUserData(data);
      } else if (response.status === 401) {
        console.log("Unauthorized, redirecting to login");
        navigate("/");
      } else {
        console.error("Error fetching user info:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching user info:", error);
    }
  };

  const fetchExams = async () => {
    const url = getApiUrl("/api/get_exams_overview");
    console.log("Fetching exams from:", url);
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        const activeExams = data.exams.filter((exam) => !exam.archive_status);
        setExams(activeExams);

        // setExams(data.exams);
      } else if (response.status === 401) {
        navigate("/");
      } else {
        console.error("Error fetching exams:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching exams:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleModalSubmit = async (examData) => {
    setIsLoading(true);
    try {
      const response = await fetch(getApiUrl("/api/create_exam"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(examData),
      });
      const result = await response.json();
      console.log("Response:", result);
      await fetchExams(); // Refresh the exam list
    } catch (error) {
      console.error("Error creating exam:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleManageClick = (examId) => {
    navigate(`/manage/${examId}`);
  };
  const handleArchive = async (examId) => {
    // Immediately remove the exam from the list
    setExams((prevExams) =>
      prevExams.filter((exam) => exam.exam_id !== examId)
    );

    try {
      const response = await fetch(getApiUrl("/api/archive_exam"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ exam_id: examId }),
      });
      if (!response.ok) {
        console.error("Error archiving exam:", response.statusText);
        // If the API call fails, we might want to add the exam back to the list
        await fetchExams(); // This will refresh the exam list from the server
      }
    } catch (error) {
      console.error("Error archiving exam:", error);
      await fetchExams(); // Refresh the list in case of error
    }
  };
  const buttons = [
    <button
      key="create-exam"
      onClick={() => setIsModalOpen(true)}
      className="text-white hover:text-FEOrange transition"
    >
      Create Exam
    </button>,
    <button
      key="archive"
      onClick={() => navigate("/archive")}
      className="text-white hover:text-FEOrange transition"
    >
      Archive
    </button>,
    <button
      key="extract-table"
      onClick={() => navigate("/table-extract")}
      className="text-white hover:text-FEOrange transition"
    >
      Extract Table
    </button>,
  ];
  

  if (userData && userData.UserRole === "admin") {
    console.log("Adding 'Manage Team' button for admin user");
    buttons.push(
      <button
        key="manage-team"
        onClick={() => navigate("/team")}
        className="text-white hover:text-FEOrange transition"
      >
        Manage Team
      </button>
    );
  }

  return (
    <div>
      <Header buttons={buttons} />
      <div className="max-w-6xl mx-auto p-4 mt-16">
        {isLoading ? (
          <LoadingOverlay isLoading={true} />
        ) : exams.length === 0 ? (
          <div className="text-center py-8 text-gray-500">
            <p>No active exams.</p>
            <p>Click "Create Exam" to start a new exam.</p>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {exams.map((exam) => (
              <div key={exam.exam_id}>
                <ExamItem
                  exam={exam}
                  handleManageClick={handleManageClick}
                  onArchive={handleArchive}
                />
              </div>
            ))}
          </div>
        )}
        {isModalOpen && (
          <NewExamModal
            onSubmit={handleModalSubmit}
            onClose={() => setIsModalOpen(false)}
          />
        )}
      </div>
    </div>
  );

}



export default DashboardPage;
