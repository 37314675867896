import React, {
  useMemo,
  useState,
  useCallback,
  useRef,
  useEffect,
} from "react";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { GripVertical, Pencil, Save, Download, RotateCcw } from "lucide-react";
import { debounce } from "lodash";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { BasicButtonClass } from "./Styles";
import getApiUrl from "../config";

const ExamReportTable = ({ examReport, currentTest, onDataChange }) => {
  const [tableData, setTableData] = useState(null);
  const [trueOriginalData, setTrueOriginalData] = useState(null);
  const [visibleColumns, setVisibleColumns] = useState({});
  const [columnOrder, setColumnOrder] = useState([]);
  const [tableHeight, setTableHeight] = useState(0);
  const [editingCell, setEditingCell] = useState(null);
  const [editingHeader, setEditingHeader] = useState(null);
  const [columnMapping, setColumnMapping] = useState({});
  const [editedCells, setEditedCells] = useState({});
  const [saveStatus, setSaveStatus] = useState("idle"); // 'idle', 'saving', 'saved', 'error'
  const tableRef = useRef(null);
  const inputRef = useRef(null);
  const saveTimeoutRef = useRef(null);
  const unsavedChangesRef = useRef(false);

  useEffect(() => {
    if (examReport && currentTest) {
      initializeData();
    }
  }, [examReport, currentTest]);
  const handleDownload = useCallback(() => {
    if (tableData && tableData.rows) {
      // Get the filtered and ordered columns
      const filteredColumns = columnOrder.filter((col) => visibleColumns[col]);

      // Create the header row with custom column names
      const header = filteredColumns.map((col) => columnMapping[col] || col);

      // Create the data rows
      const data = tableData.rows.map((row) =>
        filteredColumns.map((col) => {
          const cellData = row[col];
          return cellData.value !== undefined ? String(cellData.value) : "";
        })
      );

      // Combine header and data
      const wsData = [header, ...data];

      // Create a new workbook and worksheet
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(wsData);

      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, "ExamReport");

      // Generate XLSX file
      const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

      // Create Blob and save file
      const blob = new Blob([wbout], { type: "application/octet-stream" });
      saveAs(blob, `${currentTest}_exam_report.xlsx`);
    } else {
      console.log("No table data available for download.");
    }
  }, [tableData, columnOrder, visibleColumns, columnMapping, currentTest]);

  const initializeData = useCallback(() => {
    if (!examReport || !currentTest || !examReport[currentTest.toUpperCase()]) {
      // Clear all data and states when there's no data for the current test
      setTableData(null);
      setTrueOriginalData(null);
      setVisibleColumns({});
      setColumnOrder([]);
      setColumnMapping({});
      setEditedCells({});
      return;
    }
    const testData = examReport[currentTest.toUpperCase()];
    const samples = Object.keys(testData);
    const allKeys = new Set();
    samples.forEach((sample) => {
      Object.keys(testData[sample]).forEach((key) => allKeys.add(key));
    });

    const currentUrl = window.location.href;
    // const baseUrl = currentUrl.substring(0, currentUrl.lastIndexOf("/") + 1);
    const baseUrl = currentUrl+"/";


    // Custom sorting function for columns
    const sortColumns = (a, b) => {
      if (a === "Sample") return -1;
      if (b === "Sample") return 1;
      if (a === "URL") return 1;
      if (b === "URL") return -1;

      const aHasColon = a.includes(":");
      const bHasColon = b.includes(":");

      if (aHasColon && !bHasColon) return 1;
      if (!aHasColon && bHasColon) return -1;

      return a.localeCompare(b);
    };

    const columns = ["Sample", ...Array.from(allKeys).sort(sortColumns), "URL"];
    const rows = samples.map((sample) => {
      const row = { Sample: { value: sample, match: null } };
      columns.forEach((column) => {
        if (column === "URL") {
          row[column] = { value: `${baseUrl}${currentTest}/${sample}`, match: null };
        } else if (column !== "Sample") {
          const value = testData[sample][column];
          if (typeof value === "object" && value !== null) {
            if (value.value !== undefined) {
              row[column] = { value: value.value, match: value.match };
            } else {
              row[column] = { value: JSON.stringify(value), match: null };
            }
          } else {
            row[column] = { value: value, match: null };
          }
        }
      });
      return row;
    });
    const data = { columns, rows };
    setTrueOriginalData(JSON.parse(JSON.stringify(data)));
    setTableData(data);

    setVisibleColumns(
      columns.reduce((acc, col) => ({ ...acc, [col]: true }), {})
    );
    setColumnOrder(columns);
    setColumnMapping(
      columns.reduce((acc, col) => ({ ...acc, [col]: col }), {})
    );
    loadSavedData(data);
  }, [examReport, currentTest]);

  const loadSavedData = async (originalData) => {
    try {
      const response = await fetch(
        getApiUrl(`/api/load_exam_report/${currentTest}`)
      );
      if (!response.ok) {
        throw new Error("Failed to load saved data");
      }
      const savedData = await response.json();
      console.log("Saved data:", savedData);
      if (
        savedData.tableData &&
        savedData.tableData.columns &&
        savedData.tableData.columns.length > 0
      ) {
        setTableData(savedData.tableData);
        setVisibleColumns(savedData.visibleColumns);
        setColumnOrder(savedData.columnOrder);
        setColumnMapping(savedData.columnMapping);
        updateEditedCells(savedData.tableData, originalData);
      } else {
        updateEditedCells(originalData, originalData);
      }
    } catch (error) {
      console.error("Error loading saved data:", error);
      updateEditedCells(originalData, originalData);
    }
  };

  const updateEditedCells = useCallback((currentData, originalData) => {
    const newEditedCells = {};
    currentData.rows.forEach((row, rowIndex) => {
      Object.keys(row).forEach((column) => {
        if (
          JSON.stringify(row[column].value) !==
          JSON.stringify(originalData.rows[rowIndex][column].value)
        ) {
          newEditedCells[`${rowIndex}-${column}`] = true;
        }
      });
    });
    setEditedCells(newEditedCells);
  }, []);

  const saveChanges = useCallback(async () => {
    setSaveStatus("saving");
    try {
      const response = await fetch(
        getApiUrl(`/api/save_exam_report/${currentTest}`),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            tableData,
            visibleColumns,
            columnOrder,
            columnMapping,
            editedCells,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to save changes");
      }
      setSaveStatus("saved");
      unsavedChangesRef.current = false;
      // Reset to idle after a delay
      setTimeout(() => setSaveStatus("idle"), 2000);
    } catch (error) {
      console.error("Error saving changes:", error);
      setSaveStatus("error");
    }
  }, [
    currentTest,
    tableData,
    visibleColumns,
    columnOrder,
    columnMapping,
    editedCells,
  ]);

  // const debouncedSaveChanges = useMemo(() => {
  //   console.log("Creating debounced function");
  //   const debouncedFn = debounce(() => {
  //     console.log("Debounced function called");
  //     saveChanges();
  //   }, 1000);
  //   console.log("Debounced function created:", debouncedFn);
  //   return debouncedFn;
  // }, [saveChanges]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (unsavedChangesRef.current) {
        saveChanges();
      }
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden" && unsavedChangesRef.current) {
        saveChanges();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [saveChanges]);

  const triggerSave = useCallback(() => {
    setSaveStatus("pending");
    unsavedChangesRef.current = true;
    saveChanges(); // Call saveChanges directly
  }, [saveChanges]);

  const toggleColumn = useCallback(
    (column) => {
      setVisibleColumns((prev) => ({ ...prev, [column]: !prev[column] }));
      triggerSave();
    },
    [triggerSave]
  );

  const onDragEnd = useCallback(
    (result) => {
      if (!result.destination) return;

      const newColumnOrder = Array.from(columnOrder);
      const [reorderedItem] = newColumnOrder.splice(result.source.index, 1);
      newColumnOrder.splice(result.destination.index, 0, reorderedItem);

      setColumnOrder(newColumnOrder);
      triggerSave();
    },
    [columnOrder, triggerSave]
  );

  const formatColumnName = useCallback((column) => {
    const parts = column.split(":").map((part) =>
      part
        .split("_")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ")
    );

    if (parts.length > 1) {
      return (
        <>
          <strong>{parts[0]}</strong>
          <br />
          {parts[1]}
        </>
      );
    }
    return parts[0];
  }, []);

  // const handleCellClick = useCallback((rowIndex, column) => {
  //   setEditingCell({ rowIndex, column });
  //   setEditingHeader(null);
  // }, []);
  const handleCellClick = useCallback(
    (rowIndex, column) => {
      setEditingCell({ rowIndex, column });
      setEditingHeader(null);

      // Log the value and match of the clicked cell
      const cellData = tableData.rows[rowIndex][column];
      console.log(`Clicked cell (${rowIndex}, ${column}):`, {
        value: cellData.value,
        match: cellData.match,
      });
    },
    [tableData]
  );

  const handleHeaderClick = useCallback((column) => {
    setEditingHeader(column);
    setEditingCell(null);
  }, []);

  const handleCellChange = useCallback(
    (rowIndex, column, newValue) => {
      setTableData((prevData) => {
        const newRows = [...prevData.rows];
        const oldCellData = newRows[rowIndex][column];
        const originalValue = trueOriginalData.rows[rowIndex][column].value;

        // Only update if the value has actually changed
        if (newValue !== oldCellData.value) {
          newRows[rowIndex] = {
            ...newRows[rowIndex],
            [column]: { value: newValue, match: null },
          };

          // Mark as edited only if it's different from the original value
          if (newValue !== originalValue) {
            setEditedCells((prev) => ({
              ...prev,
              [`${rowIndex}-${column}`]: true,
            }));
          } else {
            setEditedCells((prev) => {
              const newEditedCells = { ...prev };
              delete newEditedCells[`${rowIndex}-${column}`];
              return newEditedCells;
            });
          }

          if (onDataChange) {
            onDataChange(rowIndex, column, newValue);
          }
          triggerSave();
          return { ...prevData, rows: newRows };
        }
        return prevData; // Return previous state if no change
      });
    },
    [onDataChange, trueOriginalData, triggerSave]
  );

  const handleHeaderChange = useCallback(
    (oldColumn, newColumn) => {
      setColumnMapping((prev) => ({ ...prev, [oldColumn]: newColumn }));
      if (onDataChange) {
        onDataChange("header", oldColumn, newColumn);
      }
      triggerSave();
    },
    [onDataChange, triggerSave]
  );

  const handleKeyDown = useCallback(
    (e, rowIndex, column) => {
      if (e.key === "Enter") {
        if (editingCell) {
          handleCellChange(rowIndex, column, e.target.value);
          setEditingCell(null);
        } else if (editingHeader) {
          handleHeaderChange(column, e.target.value);
          setEditingHeader(null);
        }
      } else if (e.key === "Escape") {
        setEditingCell(null);
        setEditingHeader(null);
      }
    },
    [handleCellChange, handleHeaderChange, editingCell, editingHeader]
  );

  const restoreOriginal = useCallback(() => {
    const confirmed = window.confirm(
      "Are you sure you want to restore the table to its original state? Any changes will be lost."
    );
    if (confirmed) {
      setTableData(JSON.parse(JSON.stringify(trueOriginalData)));
      setColumnMapping(
        trueOriginalData.columns.reduce(
          (acc, col) => ({ ...acc, [col]: col }),
          {}
        )
      );
      setColumnOrder(trueOriginalData.columns);
      setVisibleColumns(
        trueOriginalData.columns.reduce(
          (acc, col) => ({ ...acc, [col]: true }),
          {}
        )
      );
      setEditedCells({});
      triggerSave();
    }
  }, [trueOriginalData, triggerSave]);

  const restoreCell = useCallback(
    (rowIndex, column) => {
      setTableData((prevData) => {
        const newRows = [...prevData.rows];
        newRows[rowIndex] = {
          ...newRows[rowIndex],
          [column]: {
            value: trueOriginalData.rows[rowIndex][column].value,
            match: trueOriginalData.rows[rowIndex][column].match, // Restore original match value
          },
        };
        return { ...prevData, rows: newRows };
      });
      setEditedCells((prev) => {
        const newEditedCells = { ...prev };
        delete newEditedCells[`${rowIndex}-${column}`];
        return newEditedCells;
      });
      if (onDataChange) {
        onDataChange(
          rowIndex,
          column,
          trueOriginalData.rows[rowIndex][column].value
        );
      }
      triggerSave();
    },
    [trueOriginalData, onDataChange, triggerSave]
  );

  if (!tableData) {
    return;
  }
  const getCellBackgroundColor = (match) => {
    switch (match) {
      case "mismatch":
        return "bg-red-100";
      case "ambiguous":
        return "bg-orange-100";
      case "missing":
        return "bg-yellow-100";
      default:
        return "";
    }
  };
  const filteredColumns = columnOrder.filter((col) => visibleColumns[col]);

  return (
    <div className="mt-8 mb-4 mx-4 flex flex-col md:flex-row rounded-lg p-4">
      <div className="w-full md:w-64 mb-4 md:mb-0 md:mr-4 flex-shrink-0">
        <div
          className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden"
          style={{ height: `${Math.max(tableHeight, 600)}px` }}
        >
          <h3 className="text-lg font-semibold p-4 bg-gray-50 border-b border-gray-200">
            Column Selection
          </h3>
          <div
            className="overflow-y-auto"
            style={{
              height: `calc(${Math.max(tableHeight, 600)}px - 3.5rem)`,
            }}
          >
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="column-list">
                {(provided) => (
                  <fieldset
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="space-y-2 p-4"
                  >
                    <legend className="sr-only">Column Checkboxes</legend>
                    {columnOrder.map((column, index) => (
                      <Draggable
                        key={column}
                        draggableId={column}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className={`
                              border border-gray-200 rounded-md p-2 mb-2 
                              ${
                                snapshot.isDragging
                                  ? "bg-gray-200"
                                  : "bg-gray-50"
                              }
                              transition-colors duration-200 ease-in-out
                            `}
                          >
                            <div className="flex items-center justify-between">
                              <label
                                htmlFor={`checkbox-${column}`}
                                className="flex cursor-pointer items-center gap-3 flex-grow"
                              >
                                <div className="flex items-center h-5">
                                  <input
                                    type="checkbox"
                                    className="size-4 rounded border-gray-300"
                                    id={`checkbox-${column}`}
                                    checked={visibleColumns[column]}
                                    onChange={() => toggleColumn(column)}
                                  />
                                </div>
                                <div className="text-sm">
                                  {formatColumnName(
                                    columnMapping[column] || column
                                  )}
                                </div>
                              </label>
                              <div
                                {...provided.dragHandleProps}
                                className="cursor-move"
                              >
                                <GripVertical
                                  className="text-gray-400"
                                  size={20}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </fieldset>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      </div>
      <div className="flex-grow overflow-hidden">
        <div
          ref={tableRef}
          className="w-full overflow-x-auto border border-gray-300 rounded-lg shadow-sm bg-white"
        >
          <table className="w-full table-auto divide-y divide-gray-300 text-sm">
            <thead className="bg-gray-50">
              <tr className="divide-x divide-gray-300">
                {filteredColumns.map((column) => (
                  <th
                    key={column}
                    className="px-4 py-3 font-medium text-gray-900 text-left whitespace-nowrap"
                    onClick={() => handleHeaderClick(column)}
                  >
                    {editingHeader === column ? (
                      <input
                        ref={inputRef}
                        type="text"
                        className="w-full h-full border-none focus:outline-none bg-blue-50 font-medium"
                        defaultValue={columnMapping[column] || column}
                        onBlur={(e) => {
                          handleHeaderChange(column, e.target.value);
                          setEditingHeader(null);
                        }}
                        onKeyDown={(e) => handleKeyDown(e, null, column)}
                      />
                    ) : (
                      formatColumnName(columnMapping[column] || column)
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-300">
              {tableData.rows.map((row, rowIndex) => (
                <tr key={rowIndex} className="divide-x divide-gray-300">
                  {filteredColumns.map((column) => {
                    const cellData = row[column];
                    const bgColorClass = getCellBackgroundColor(cellData.match);
                    const isEdited = editedCells[`${rowIndex}-${column}`];
                    return (
                      <td
                        key={column}
                        className={`px-4 py-3 text-gray-700 text-left whitespace-nowrap relative ${bgColorClass}`}
                        onClick={() => handleCellClick(rowIndex, column)}
                      >
                        {editingCell?.rowIndex === rowIndex &&
                        editingCell?.column === column ? (
                          <input
                            ref={inputRef}
                            type="text"
                            className={`w-full h-full border-none focus:outline-none ${bgColorClass}`}
                            defaultValue={
                              cellData.value !== undefined
                                ? String(cellData.value)
                                : ""
                            }
                            onBlur={(e) => {
                              handleCellChange(
                                rowIndex,
                                column,
                                e.target.value
                              );
                              setEditingCell(null);
                            }}
                            onKeyDown={(e) =>
                              handleKeyDown(e, rowIndex, column)
                            }
                          />
                        ) : (
                          <>
                            {cellData.value !== undefined
                              ? String(cellData.value)
                              : ""}
                            {isEdited && (
                              <button
                                className="absolute top-0 right-0 p-1 text-blue-500 hover:text-blue-700"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  restoreCell(rowIndex, column);
                                }}
                                title="Restore original value"
                              >
                                <Pencil size={12} />
                              </button>
                            )}
                          </>
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mt-4 flex justify-between items-center">
          <div>
            {saveStatus === "error" && (
              <p className="text-red-500 text-sm">
                Failed to save changes. Please try again.
              </p>
            )}
          </div>
          <div className="flex justify-between w-full">
            <button onClick={handleDownload} className={`${BasicButtonClass}`}>
              <Download className="inline-block mr-2" size={16} />
              Download Report
            </button>
            <button onClick={restoreOriginal} className={`${BasicButtonClass}`}>
              <RotateCcw className="inline-block mr-2" size={16} />
              Restore Original
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExamReportTable;
