import React from "react";
import { Link } from "react-router-dom";

const testNames = {
  invoice: "Invoice Test",
  cash: "Cash Application Test",
  vendor: "Vendor Payment Test",
  ar_ap_analysis: "AR/AP Analysis",
  gross_profit: "Gross Profit Test"
};

const TestTabs = ({
  examData,
  currentTest,
  setCurrentTest,
  isSidebarOpen,
  isTabsExtended,
  onLogoMouseEnter,
  onTabsMouseEnter,
  onTabsMouseLeave,
}) => {
  const testOrder = ["invoice", "cash", "vendor", "gross_profit", "ar_ap_analysis"];
  if (!examData) return null;

  const availableTests = Object.keys(examData);
  const orderedTests = testOrder.filter((test) =>
    availableTests.includes(test)
  );
  availableTests.forEach((test) => {
    if (!orderedTests.includes(test)) {
      orderedTests.push(test);
    }
  });

  return (
    <div
      className="bg-FEBlue fixed shadow-md w-full top-0 left-0 h-16 flex items-center"
      onMouseEnter={onTabsMouseEnter}
      onMouseLeave={onTabsMouseLeave}
    >
      <Link
        to="/dashboard"
        className="flex items-center ml-4 cursor-pointer logo-container"
        onMouseEnter={onLogoMouseEnter}
      >
        <img src="/FE360_NBG_square.png" alt="Logo" className="h-12" />
        <div
          className={`overflow-hidden transition-all duration-300 ease-in-out ${
            isTabsExtended ? "w-44 opacity-100" : "w-0 opacity-0"
          }`}
        >
          <span className="text-white text-xl font-bold ml-4 whitespace-nowrap">
            {/* ExamFlow */}
            ExamFlow
          </span>
        </div>
      </Link>
      <div
        className={`flex-1 transition-all duration-300 ease-in-out ${
          isTabsExtended ? "ml-0" : "ml-4"
        }`}
      >
        <div className="hidden sm:block">
          <nav className="flex gap-4" aria-label="Tabs">
            {orderedTests.map((test) => (
              <button
                key={test}
                onClick={() => setCurrentTest(test)}
                className={`shrink-0 rounded-lg p-2 text-md font-medium transition-colors duration-200 ${
                  currentTest === test
                    ? "bg-FEOrange text-white"
                    : "text-white hover:text-FEOrange"
                }`}
                aria-current={currentTest === test ? "page" : undefined}
              >
                {testNames[test] || test}
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default TestTabs;
