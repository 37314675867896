
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ExamItem from "./components/ExamItem";
import Header from "./components/Header";
import LoadingOverlay from "./components/LoadingOverlay";
import getApiUrl from "./config";

function ArchivePage() {
  const navigate = useNavigate();
  const [exams, setExams] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchArchivedExams();
  }, []);

  const fetchArchivedExams = async () => {
    const url = getApiUrl("/api/get_exams_overview");
    console.log("Fetching archived exams from:", url);
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        const archivedExams = data.exams.filter((exam) => exam.archive_status);
        setExams(archivedExams);
      } else if (response.status === 401) {
        navigate("/");
      } else {
        console.error("Error fetching archived exams:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching archived exams:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRestore = async (examId) => {
    setExams((prevExams) =>
      prevExams.filter((exam) => exam.exam_id !== examId)
    );
    try {
      const response = await fetch(
        getApiUrl(getApiUrl("/api/unarchive_exam")),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ exam_id: examId }),
        }
      );
      if (!response.ok) {
        console.error("Error restoring exam:", response.statusText);
        await fetchArchivedExams();
      }
    } catch (error) {
      console.error("Error restoring exam:", error);
      await fetchArchivedExams();
    }
  };

  const buttons = [
    <button
      key="dashboard"
      onClick={() => navigate("/dashboard")}
      className="text-white hover:text-FEOrange transition"
    >
      Dashboard
    </button>,
  ];

  return (
    <div>
      <Header buttons={buttons} />
      <div className="max-w-6xl mx-auto p-4 mt-16">
        {isLoading ? (
          <LoadingOverlay isLoading={true} />
        ) : exams.length === 0 ? (
          <div className="text-center py-8 text-gray-500">
            No archived exams.
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {exams.map((exam) => (
              <ExamItem
                key={exam.exam_id}
                exam={exam}
                onArchive={handleRestore}
                isArchivePage={true}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default ArchivePage;