import { useEffect, useRef } from "react";
function ImageModal({ isOpen, onClose, imageSrc, altText }) {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("keydown", handleEscape);
    }

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center p-4"
      onClick={onClose}
      style={{
        backdropFilter: "blur(2px)",
      }}
    >
      <div
        ref={modalRef}
        className="max-w-[90vw] max-h-[90vh] overflow-auto bg-white rounded-lg shadow-lg"
        onClick={(e) => e.stopPropagation()}
      >
        <img
          src={imageSrc}
          alt={altText}
          className="w-full h-auto object-contain"
        />
      </div>
    </div>
  );
}
export default ImageModal;