import React, { useState } from "react";
import InputField from "./InputField";

const AddCollaboratorsModal = ({
  collaborators,
  onSubmit,
  onClose,
  onRemoveCollaborator,
}) => {
  const [emails, setEmails] = useState([""]);

  const handleAddEmail = () => {
    setEmails([...emails, ""]);
  };

  const handleEmailChange = (index, event) => {
    const newEmails = [...emails];
    newEmails[index] = event.target.value;
    setEmails(newEmails);
  };

  const handleRemoveEmail = (index) => {
    const newEmails = emails.filter((_, i) => i !== index);
    setEmails(newEmails);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(emails);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="mb-4">Current Editors</h2>
        <ul>
          {collaborators.map((email, index) => (
            <li key={index} className="mb-2 flex items-center">
              {email}
              <button
                onClick={() => onRemoveCollaborator(email)}
                className="ml-2 p-1 text-red-600"
              >
                Remove
              </button>
            </li>
          ))}
        </ul>
        <form onSubmit={handleSubmit}>
          <div className="email-fields mt-4">
            {emails.map((email, index) => (
              <div key={index} className="email-field mb-2 flex items-center">
                <InputField
                  label={`Email ${index + 1}`}
                  value={email}
                  onChange={(e) => handleEmailChange(index, e)}
                />
                {emails.length > 1 && (
                  <button
                    type="button"
                    onClick={() => handleRemoveEmail(index)}
                    className="ml-2 p-1 text-red-600"
                  >
                    Remove
                  </button>
                )}
              </div>
            ))}
            <button
              type="button"
              onClick={handleAddEmail}
              className="mt-2 p-2 text-blue-600"
            >
              + Add Email
            </button>
          </div>
          <div className="actions mt-4 flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="mr-2 p-2 bg-gray-600 text-white rounded-md"
            >
              Close
            </button>
            <button
              type="submit"
              className="p-2 bg-blue-600 text-white rounded-md"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCollaboratorsModal;
