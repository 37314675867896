import React from "react";
import ImageOrTable from "./ImageOrTable";

function ImageGroup({ images, groupKey, onImageClick }) {
  if (!images || !images[groupKey]) return null;

  return (
    <div className="flex-shrink-0 ml-4">
      <div className="flex flex-row space-x-2 overflow-x-auto">
        {Object.entries(images[groupKey]).map(([subKey, imgData]) => (
          <ImageOrTable
            key={subKey}
            data={imgData}
            subKey={subKey}
            title={subKey.replace(/_/g, " ")}
            onImageClick={onImageClick}
          />
        ))}
      </div>
    </div>
  );
}

export default ImageGroup;
