import React from "react";

const buttonBaseClass =
  "inline-block rounded px-8 py-3 text-sm font-medium transition hover:scale-110 hover:shadow-xl focus:outline-none focus:ring";
const buttonVariantClass = {
  base: `bg-FEOrange text-white active:bg-FEOrangeActive`,
  border: `border border-current text-FEOrange active:text-FEOrangeActive`,
};

const Button = ({ text, url, variant = "base" }) => {
  return (
    <a
      className={`${buttonBaseClass} ${buttonVariantClass[variant]}`}
      href={url}
    >
      {text}
    </a>
  );
};

const InvalidUserPage = () => {
  return (
    <section className="bg-white min-h-screen flex items-center justify-center">
      <div className="max-w-2xl mx-auto px-4 py-16 text-center">
        <h1 className="text-4xl font-bold mb-6 text-black">
          <span className="text-FEBlue">Access Denied</span>
        </h1>
        <p className="text-xl mb-4 text-black">
          This email is not associated with a ExamFlow account.
        </p>
        <br />
        <br />
        <p className="text-lg mb-4 text-black">
          If you were expecting to have access,
          <br />
          contact your company admin to ensure your email is added.
        </p>
        <p className="text-lg mb-8 text-black">
          If you would like to onboard your company to ExamFlow,
          <br />
          please reach out to us at{" "}
          <a
            href="mailto:team@ExamFlow.i0?subject=ExamFlow%20Inquiry"
            className="bold underline hover:text-FEOrangeActive"
          >
            team@ExamFlow.io
          </a>
        </p>
        <br />
        <br />
        <div className="flex justify-center">
          <div className="flex flex-col gap-4">
            <Button
              text="Contact Sales"
              url="mailto:team@examflow.io?subject=ExamFlow%20Inquiry"
              variant="base"
            />
            <Button text="Log In" url="/" variant="border" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default InvalidUserPage;
