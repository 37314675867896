import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { useParams, useNavigate } from "react-router-dom";
import AddCollaboratorsModal from "./components/AddCollaboratorsModal";
import LoadingOverlay from "./components/LoadingOverlay";
import ReportComponent from "./components/report/ReportComponent";
import ResultsSelector from "./components/ResultsSelector";
import Sidebar from "./components/Sidebar";
import TestTabs from "./components/TestTabs";
import SamplesTable from "./components/SamplesTable";
import UnmatchedDocs from "./components/UnmatchedDocs";
import ExamReportTable from "./components/ExamReportTable";
import useExamData from "./hooks/useExamData";
import useTestResults from "./hooks/useTestResults";
import useCollaborators from "./hooks/useCollaborators";
import SampleRequestSection from "./components/SampleRequestSection";
import getApiUrl from "./config";

import {
  handleDownloadExcel,
  handleRunExam,
  handleCellClick,
} from "./utils/examUtils";

function ManagePage() {
  const { examId, test: urlTest } = useParams();

  const navigate = useNavigate();

  const {
    examData,
    setExamData,
    resultsData,
    examReport,
    isLoading,
    setResultsData,
    updateExamDataLocally,
  } = useExamData(examId);
  const [currentTest, setCurrentTest] = useState(null);
  const [currentSample, setCurrentSample] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);

  const { collaborators, addCollaborators, removeCollaborator } =
    useCollaborators(examId);
  const { selectedKey, setSelectedKey, testResultsData } = useTestResults(
    examData,
    resultsData,
    currentTest
  );

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isRunningExam, setIsRunningExam] = useState(false);
  const [examStatus, setExamStatus] = useState(null);

  const [isUploading, setIsUploading] = useState(false);
  const [uploadedData, setUploadedData] = useState(null);

  // sidebar code
  const [isPinned, setIsPinned] = useState(() => {
    const savedPinnedState = localStorage.getItem("sidebarPinned");
    return savedPinnedState !== null ? JSON.parse(savedPinnedState) : false;
  });
  const [isSidebarOpen, setIsSidebarOpen] = useState(isPinned);
  const [isTabsExtended, setIsTabsExtended] = useState(false);
  const [isHoveringTabsOrSidebar, setIsHoveringTabsOrSidebar] = useState(false);

  const sidebarTimerRef = useRef(null);
  const tabsTimerRef = useRef(null);

  const SIDEBAR_DELAY = 300;
  const TABS_DELAY = 1000;

  const scrollSpeedRef = useRef(5); // Adjust scroll speed as needed
  const scrollThreshold = 0.2; // Top and bottom 20% of the viewport
  const isDraggingRef = useRef(false);

  const handleGlobalDragOver = useCallback((e) => {
    if (!isDraggingRef.current) return;

    const { clientY } = e;
    const viewportHeight = window.innerHeight;
    const topThreshold = viewportHeight * scrollThreshold;
    const bottomThreshold = viewportHeight * (1 - scrollThreshold);

    if (clientY < topThreshold) {
      // Scroll up
      window.scrollBy(0, -scrollSpeedRef.current);
    } else if (clientY > bottomThreshold) {
      // Scroll down
      window.scrollBy(0, scrollSpeedRef.current);
    }
  }, []);

  const handleGlobalDragEnd = useCallback(() => {
    isDraggingRef.current = false;
  }, []);

  const handleGlobalDragStart = useCallback(() => {
    isDraggingRef.current = true;
  }, []);

  useEffect(() => {
    document.addEventListener("dragover", handleGlobalDragOver);
    document.addEventListener("dragend", handleGlobalDragEnd);
    document.addEventListener("drop", handleGlobalDragEnd);
    // Adding dragstart listener to capture the start of dragging
    document.addEventListener("dragstart", handleGlobalDragStart);

    return () => {
      document.removeEventListener("dragover", handleGlobalDragOver);
      document.removeEventListener("dragend", handleGlobalDragEnd);
      document.removeEventListener("drop", handleGlobalDragEnd);
      document.removeEventListener("dragstart", handleGlobalDragStart);
    };
  }, [handleGlobalDragOver, handleGlobalDragEnd, handleGlobalDragStart]);

  const handleLogoMouseEnter = useCallback(() => {
    setIsHoveringTabsOrSidebar(true);
    setIsSidebarOpen(true);
    setIsTabsExtended(true);
    clearTimeout(sidebarTimerRef.current);
    clearTimeout(tabsTimerRef.current);
  }, []);

  const handleTabsMouseEnter = useCallback(() => {
    setIsHoveringTabsOrSidebar(true);
    clearTimeout(tabsTimerRef.current);
  }, []);

  const handleTabsMouseLeave = useCallback(() => {
    setIsHoveringTabsOrSidebar(false);
    if (!isPinned) {
      sidebarTimerRef.current = setTimeout(() => {
        setIsSidebarOpen(false);
      }, SIDEBAR_DELAY);

      tabsTimerRef.current = setTimeout(() => {
        setIsTabsExtended(false);
      }, TABS_DELAY);
    }
  }, [isPinned]);

  const handleSidebarMouseEnter = useCallback(() => {
    setIsHoveringTabsOrSidebar(true);
    clearTimeout(sidebarTimerRef.current);
    clearTimeout(tabsTimerRef.current);
  }, []);

  const handleSidebarMouseLeave = useCallback(() => {
    setIsHoveringTabsOrSidebar(false);
    if (!isPinned) {
      const timer = setTimeout(() => {
        setIsSidebarOpen(false);
        setIsTabsExtended(false);
      }, SIDEBAR_DELAY);
      sidebarTimerRef.current = timer;
      tabsTimerRef.current = timer;
    }
  }, [isPinned]);

  useEffect(() => {
    if (currentTest && examData && examData[currentTest]) {
      const samples = examData[currentTest].samples;
      if (samples && Object.keys(samples).length > 0) {
        const firstSample = Object.keys(samples)[0];
        setCurrentSample(firstSample);
        setSelectedKey(firstSample);
      }
    }
  }, [currentTest, examData, setSelectedKey]);

  useEffect(() => {
    return () => {
      clearTimeout(sidebarTimerRef.current);
      clearTimeout(tabsTimerRef.current);
    };
  }, []);

  useEffect(() => {
    if (examData && !currentTest) {
      const firstAvailableTest = Object.keys(examData)[0];
      setCurrentTest(firstAvailableTest);
    }
  }, [examData, currentTest]);

  // useEffect(() => {
  //   if (currentTest) {
  //     console.log(`Current Test: ${currentTest}`);
  //     navigate(`/manage/${examId}/${currentTest}`, { replace: true });
  //   }
  // }, [currentTest, examId, navigate]);

  useEffect(() => {
    if (examData && !isInitialized) {
      let newTest =
        urlTest && examData[urlTest] ? urlTest : Object.keys(examData)[0];

      if (newTest && examData[newTest]) {
        setCurrentTest(newTest);
      } else {
      }

      setIsInitialized(true);
    }
  }, [examData, urlTest, isInitialized]);

  // useEffect(() => {
  //   if (examData) {
  //     const eventSource = new EventSource(
  //       `/sse_exam_details?exam_id=${examId}`
  //     );

  //     eventSource.onmessage = (event) => {
  //       if (event.data === "heartbeat") {
  //         console.log("Received SSE heartbeat");
  //         return; // Ignore heartbeat messages
  //       }

  //       const data = JSON.parse(event.data);
  //       console.log("Received SSE update:", data);

  //       setExamData((prevData) => {
  //         const updatedData = { ...prevData };
  //         for (const testType in data) {
  //           if (updatedData[testType]) {
  //             updatedData[testType] = {
  //               ...updatedData[testType],
  //               ...data[testType],
  //             };
  //           } else {
  //             updatedData[testType] = data[testType];
  //           }
  //         }
  //         return updatedData;
  //       });
  //     };

  //     eventSource.onerror = (error) => {
  //       console.error("SSE error:", error);
  //       eventSource.close();
  //     };

  //     return () => {
  //       eventSource.close();
  //     };
  //   }
  // }, [examId]);

  const handleTestChange = useCallback((newTest) => {
    setCurrentTest(newTest);
  }, []);

  // const handleSampleChange = useCallback(
  //   (newSample) => {
  //     console.log("MPMPMPhandleSampleChange called with:", newSample);
  //     setCurrentSample(newSample);
  //     setSelectedKey(newSample);
  //   },
  //   [setSelectedKey]
  // );
  const handleSampleChange = useCallback(
    (newSample) => {
      console.log("handleSampleChange called with:", newSample);
      setSelectedKey(newSample);
      // Remove setCurrentSample here
    },
    [setSelectedKey]
  );
  useEffect(() => {
    if (testResultsData && Object.keys(testResultsData).length > 0) {
      setCurrentSample(selectedKey);
    }
  }, [testResultsData, selectedKey]);

  const handlePinChange = useCallback((newPinnedState) => {
    setIsPinned(newPinnedState);
    localStorage.setItem("sidebarPinned", JSON.stringify(newPinnedState));
  }, []);

  const pollExamStatus = useCallback(async () => {
    const checkStatus = async () => {
      console.log(`Calling exam status route for exam ID: ${examId}`);
      try {
        const response = await fetch(getApiUrl(`/api/exam_status/${examId}`));
        console.log("Received response from exam status route");
        const data = await response.json();
        console.log("Exam status:", data);
        setExamStatus(data);

        if (data === "running") {
          console.log("Exam still running, will check again in 2 seconds");
          setTimeout(checkStatus, 2000);
        } else if (data === "completed" || data === "pending") {
          console.log("Exam finished, stopping polling and reloading page");
          setIsRunningExam(false);
          window.location.reload();
        } else {
          console.log("Unexpected exam status:", data);
          setIsRunningExam(false);
        }
      } catch (error) {
        console.error("Error checking exam status:", error);
        setIsRunningExam(false);
      }
    };

    console.log("Starting to poll exam status");
    checkStatus();
  }, [examId]);

  const handleRunExamClick = useCallback(async () => {
    console.log("Run Exam button clicked");
    setIsRunningExam(true);
    try {
      console.log(`Calling run exam route for exam ID: ${examId}`);
      const response = await fetch(getApiUrl(`/api/run_exam/${examId}`), {
        method: "POST",
      });
      console.log("Received response from run exam route");
      if (response.ok) {
        console.log("Exam started successfully, beginning to poll status");
        pollExamStatus(); // Start polling immediately after successful exam start
      } else {
        console.error("Failed to start exam");
        setIsRunningExam(false);
      }
    } catch (error) {
      console.error("Error starting exam:", error);
      setIsRunningExam(false);
    }
  }, [examId, pollExamStatus]);

  const handleUpdateExamData = useCallback((updatedExamData) => {
    setExamData((prevData) => {
      const newData = { ...prevData };
      for (const testType in updatedExamData) {
        if (newData[testType]) {
          newData[testType] = {
            ...newData[testType],
            ...updatedExamData[testType],
          };
        } else {
          newData[testType] = updatedExamData[testType];
        }
      }
      return newData;
    });

    setCurrentTest((prevTest) => {
      if (updatedExamData[prevTest]) {
        return prevTest;
      }
      return Object.keys(updatedExamData)[0];
    });
  }, []);

  const samplesTableKey = useMemo(() => {
    if (!examData || !currentTest || !examData[currentTest]) {
      return "samples-table-loading";
    }
    return `samples-table-${currentTest}-${JSON.stringify(
      examData[currentTest]
    )}`;
  }, [currentTest, examData]);

  const buttons = [
    <button
      key="add-collaborators"
      onClick={() => setIsAddModalOpen(true)}
      className="p-2 text-white hover:text-FEOrange transition"
    >
      Manage Collaborators
    </button>,
    <button
      key="run-exam"
      onClick={handleRunExamClick}
      className="p-2 text-white hover:text-FEOrange transition"
      disabled={isRunningExam}
    >
      {isRunningExam ? "Running..." : "Run Exam"}
    </button>,
    <button
      key="view-folder"
      onClick={() => {
        if (examData && currentTest && examData[currentTest]) {
          const folderLink = `https://app.box.com/folder/${examData[currentTest].folder_id}`;
          window.open(folderLink, "_blank");
        }
      }}
      className="p-2 text-white hover:text-FEOrange transition"
    >
      View Data Room
    </button>,
  ];

  if (isLoading || isRunningExam) {
    return <LoadingOverlay isLoading={true} />;
  }

  return (
    <div>
      <TestTabs
        examData={examData}
        currentTest={currentTest}
        setCurrentTest={handleTestChange}
        isSidebarOpen={isSidebarOpen}
        isTabsExtended={isTabsExtended}
        onLogoMouseEnter={handleLogoMouseEnter}
        onTabsMouseEnter={handleTabsMouseEnter}
        onTabsMouseLeave={handleTabsMouseLeave}
      />
      <Sidebar
        buttons={buttons}
        isOpen={isSidebarOpen}
        setIsOpen={setIsSidebarOpen}
        isPinned={isPinned}
        setIsPinned={handlePinChange}
        onMouseEnter={handleSidebarMouseEnter}
        onMouseLeave={handleSidebarMouseLeave}
      />
      <div
        className={`transition-all duration-300 ease-in-out ${
          isPinned ? "ml-56" : ""
        }`}
      >
        <div className="mt-16">
          <br />
          {examData && currentTest && (
            <>
              <SampleRequestSection
                examId={examId}
                currentTest={currentTest}
                key={currentTest}
              />
              <SamplesTable
                examData={examData}
                currentTest={currentTest}
                handleCellClick={handleCellClick}
                handleUpdateExamData={handleUpdateExamData}
              />
            </>
          )}
          {/* <UnmatchedDocs
            examData={examData}
            currentTest={currentTest}
            handleCellClick={handleCellClick}
          /> */}
          <UnmatchedDocs
            examData={examData}
            currentTest={currentTest}
            handleCellClick={handleCellClick}
            samplesData={examData[currentTest]?.samples}
          />
          {isAddModalOpen && (
            <AddCollaboratorsModal
              collaborators={collaborators}
              onSubmit={addCollaborators}
              onClose={() => setIsAddModalOpen(false)}
              onRemoveCollaborator={removeCollaborator}
            />
          )}
          <br />
          {resultsData &&
            currentTest &&
            resultsData[currentTest] &&
            Object.keys(resultsData[currentTest]).length > 0 && (
              <ResultsSelector
                keys={Object.keys(resultsData[currentTest])}
                selectedKey={currentSample}
                onSelectKey={handleSampleChange}
                samplesData={examData[currentTest]?.samples}
              />
            )}
          {testResultsData && (
            <ReportComponent
              data={testResultsData}
              testType={currentTest}
              sampleText={currentSample}
              examID={examId}
            />
          )}
          {resultsData &&
            currentTest &&
            resultsData[currentTest] &&
            Object.keys(resultsData[currentTest]).length > 0 && (
              <ResultsSelector
                keys={Object.keys(resultsData[currentTest])}
                selectedKey={currentSample}
                onSelectKey={handleSampleChange}
                samplesData={examData[currentTest]?.samples}
              />
            )}
          {examReport && currentTest && (
            <ExamReportTable
              examReport={examReport}
              currentTest={currentTest}
            />
          )}
        </div>
      </div>
      <LoadingOverlay isLoading={isLoading || isRunningExam} />
    </div>
  );
}

export default ManagePage;
