import * as XLSX from "xlsx";
import getApiUrl from "../config";

export const handleDownloadExcel = (resultsData) => {
  if (!resultsData || !resultsData.invoice) {
    alert("No invoice results data available to download.");
    return;
  }

  const columnOrder = [
    "Customer Name",
    "Invoice Number",
    "Invoice Amount",
    "Invoice Date",
    "Shipping Date",
    "Delivery Date",
    "FOB Point",
    "Terms",
    "Lag Time",
    "Proof of Shipment Document",
    "Shipper Signature",
    "Carrier Signature",
  ];

  const allTableRows = [columnOrder];

  Object.values(resultsData.invoice).forEach((resultSet) => {
    if (resultSet.table_row && typeof resultSet.table_row === "object") {
      const rowArray = columnOrder.map((key) =>
        resultSet.table_row[key] !== undefined ? resultSet.table_row[key] : ""
      );
      allTableRows.push(rowArray);
    }
  });

  if (allTableRows.length === 1) {
    alert("No table data available to download.");
    return;
  }

  const worksheet = XLSX.utils.aoa_to_sheet(allTableRows);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "ExamResults");

  XLSX.writeFile(workbook, "exam_results.xlsx");
};

export const handleRunExam = async (examId, setResultsData) => {
  try {
    const response = await fetch(getApiUrl(`/api/run_exam/${examId}`), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    if (data.status === "success") {
      // setResultsData(data.results);
      window.location.reload();

    } else {
      alert("Failed to run the exam.");
    }
  } catch (error) {
    console.error("Error running exam:", error);
    alert("An error occurred while running the exam.");
  }
};

export const openFolderInBox = (examData, currentTest) => {
  if (examData && currentTest && examData[currentTest]) {
    const folderLink = `https://app.box.com/folder/${examData[currentTest].folder_id}`;
    window.open(folderLink, "_blank");
  }
};

export const handleCellClick = (fileId) => {
  window.open(`https://app.box.com/file/${fileId}`, "_blank");
};
