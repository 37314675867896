import React from "react";
import ArrayTableResult from "./ArrayTableResult";

const AgingSummaryDisplay = ({ data }) => {
  return (
    <div className="flex justify-center">
      <ArrayTableResult data={data} />
    </div>
  );
};

export default AgingSummaryDisplay;
