export const buttonBaseClass =
  "inline-block rounded px-8 py-3 text-sm font-medium transition hover:scale-110 hover:shadow-xl focus:outline-none focus:ring";

export const buttonVariantClass = {
  base: `bg-FEOrange text-white active:bg-FEOrangeActive`,
  border: `border border-current text-FEOrange active:text-FEOrangeActive`,
};

export const BasicButtonClass = 
"inline-block rounded-lg border-2 border-FEBlue bg-FEBlue px-8 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-FEBlue focus:outline-none focus:ring active:FEBlue"


// import chroma from "chroma-js";

// export const FEOrange = "#fb8500";

// export const buttonBaseClass =
//   "inline-block rounded px-8 py-3 text-sm font-medium transition hover:scale-110 hover:shadow-xl focus:outline-none focus:ring";

// const lightenColor = (color, amount) => {
//   return chroma(color).brighten(amount).hex();
// };

// const activeFEOrange = lightenColor(FEOrange, 0.5); // adjust the amount as needed

// export const buttonVariantClass = {
//   base: `bg-[${FEOrange}] text-white active:bg-[${activeFEOrange}]`,
//   border: `border border-current text-[${FEOrange}] active:text-[${activeFEOrange}]`,
// };
