import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import DashboardPage from "./DashboardPage";
import LoginPage from "./LoginPage";
import ManagePage from "./ManagePage";
import AuthProvider from "./authProvider";
import InvalidUserPage from "./InvalidUserPage";
import TeamPage from "./TeamPage";
import ArchivePage from "./ArchivePage";
import SampleSelectionPage from "./SampleSelectionPage";
import TableExtractPage from "./TableExtractPage";

function App() {
  return (
    <Router>
      <AuthProvider>
        <div>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/table-extract" element={<TableExtractPage />} />
            <Route path="/manage/:examId" element={<ManagePage />} />
            <Route path="/manage/:examId/:test" element={<ManagePage />} />
            <Route
              path="/manage/:examId/:test/:sample"
              element={<ManagePage />}
            />
            <Route path="/invalid-user" element={<InvalidUserPage />} />
            <Route path="/team" element={<TeamPage />} />
            <Route path="/archive" element={<ArchivePage />} />
            <Route
              path="/sample-select/:examId"
              element={<SampleSelectionPage />}
            />
            <Route path="*" element={<LoginPage />} />
          </Routes>
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;