import { useState, useEffect, useCallback, useRef } from "react";
import getApiUrl from "../config";

const useExamData = (examId) => {
  const [examData, setExamData] = useState(null);
  const [resultsData, setResultsData] = useState(null);
  const [examReport, setExamReport] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const eventSourceRef = useRef(null);
  const imageQueue = useRef([]);
  const isFetchingImage = useRef(false);
  const fetchedImages = useRef(new Set());

  const parseOrderedJSON = useCallback((jsonString) => {
    return JSON.parse(jsonString, (key, value) => {
      if (value && typeof value === "object" && !Array.isArray(value)) {
        return Object.entries(value).reduce((acc, [k, v]) => {
          acc[k] = v;
          return acc;
        }, {});
      }
      return value;
    });
  }, []);

  const fetchInitialData = useCallback(async () => {
    try {
      console.log(`Fetching exam data for examId: ${examId}`);
      const [examResponse, resultsResponse, reportResponse] = await Promise.all(
        [
          fetch(getApiUrl(`/api/get_exam/${examId}`), {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }),
          fetch(getApiUrl(`/api/get_exam_results/${examId}`), {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }),
          fetch(getApiUrl(`/api/get_exam_report/${examId}`), {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }),
        ]
      );

      const examData = await examResponse.json();
      const resultsText = await resultsResponse.text();
      const examReport = await reportResponse.json();
      const resultsData = parseOrderedJSON(resultsText);

      console.log("Parsed results:", resultsData);
      console.log("Exam report:", examReport);

      setExamData(examData);
      setResultsData(resultsData);
      setExamReport(examReport);
      setIsLoading(false);

      // Initialize image queue
      for (const testKey in resultsData) {
        if (!resultsData[testKey]) continue;
        for (const sampleKey in resultsData[testKey]) {
          if (!resultsData[testKey][sampleKey]) continue;
          if (resultsData[testKey][sampleKey].images) {
            for (const imageKey in resultsData[testKey][sampleKey].images) {
              if (resultsData[testKey][sampleKey].images[imageKey] === null) {
                imageQueue.current.push({ testKey, sampleKey, imageKey });
              }
            }
          }
        }
      }
    } catch (error) {
      console.error("Error fetching initial data:", error);
      setIsLoading(false);
    }
  }, [examId, parseOrderedJSON]);

  const setupSSE = useCallback(() => {
    // eventSourceRef.current = new EventSource(
    //   `/sse_exam_details?exam_id=${examId}`
    // );

    eventSourceRef.current.onmessage = (event) => {
      // if (event.data === "heartbeat") {
      //   return; // Ignore heartbeat messages
      // }

      const data = JSON.parse(event.data);
      console.log("Received SSE update:", data);

      setExamData((prevData) => ({ ...prevData, ...data.examData }));
      if (data.resultsData) {
        setResultsData((prevData) => ({ ...prevData, ...data.resultsData }));
      }
      if (data.examReport) {
        setExamReport((prevData) => ({ ...prevData, ...data.examReport }));
      }
    };

    eventSourceRef.current.onerror = (error) => {
      console.error("SSE error:", error);
      eventSourceRef.current.close();
      setTimeout(setupSSE, 5000); // Try to reconnect after 5 seconds
    };
  }, [examId]);

  useEffect(() => {
    fetchInitialData().then(() => {
      setupSSE();
    });

    // return () => {
    //   if (eventSourceRef.current) {
    //     eventSourceRef.current.close();
    //   }
    // };
  }, [fetchInitialData, setupSSE]);

  const fetchImage = useCallback(
    async ({ testKey, sampleKey, imageKey }) => {
      const imagePath = `${testKey}/${sampleKey}/images/${imageKey}`;
      if (fetchedImages.current.has(imagePath)) {
        console.log(`Image already fetched: ${imagePath}`);
        return;
      }
      try {
        const response = await fetch(
          getApiUrl(`/api/get_exam_image/${examId}/${imagePath}`),
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch image");
        }
        const imageData = await response.json();
        setResultsData((prevData) => {
          const newData = JSON.parse(JSON.stringify(prevData));
          newData[testKey][sampleKey].images[imageKey] = imageData.image_data;
          return newData;
        });
        fetchedImages.current.add(imagePath);
      } catch (error) {
        console.error(`Error fetching image for path ${imagePath}:`, error);
      }
    },
    [examId]
  );

  const processImageQueue = useCallback(async () => {
    if (isFetchingImage.current || imageQueue.current.length === 0) return;
    isFetchingImage.current = true;
    const imageInfo = imageQueue.current.shift();
    await fetchImage(imageInfo);
    isFetchingImage.current = false;
    // Process next image in queue after a short delay
    setTimeout(processImageQueue, 1);
  }, [fetchImage]);

  useEffect(() => {
    if (resultsData && !isLoading) {
      processImageQueue();
    }
  }, [resultsData, isLoading, processImageQueue]);

  const updateExamDataLocally = useCallback((updatedExamData) => {
    console.log("Updated exam data (local):", updatedExamData);
    setExamData(updatedExamData);
  }, []);

  return {
    examData,
    setExamData,
    resultsData,
    examReport,
    isLoading,
    setResultsData,
    updateExamDataLocally,
  };
};

export default useExamData;
