import React, { useState, useEffect } from "react";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from "recharts";
import ArrayTable from "./ArrayTable";
import getApiUrl from "../../config";

const COLORS = [
  "#4e79a7",
  "#f28e2c",
  "#e15759",
  "#76b7b2",
  "#59a14f",
  "#edc949",
  "#af7aa1",
  "#ff9da7",
  "#9c755f",
  "#bab0ab",
  "#7EB0D5",
  "#A0C15A",
  "#FBD164",
  "#D3A4CF",
  "#A2A2A2",
];

const ConcentrationAnalysisDisplay = ({ data, examID, test_name }) => {
  const extraName =
    test_name === "AP Concentration Analysis"
      ? "APCompanyMap"
      : test_name === "AR Concentration Analysis"
      ? "ARCompanyMap"
      : "CompanyMap";

  const [topN, setTopN] = useState(10);
  const [tableData, setTableData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [overallTotal, setOverallTotal] = useState(0);

  // New state variables
  const [namesMap, setNamesMap] = useState({});
  const [unassignedCompanies, setUnassignedCompanies] = useState([]);
  const [showNewGroupInput, setShowNewGroupInput] = useState(false);
  const [newGroupName, setNewGroupName] = useState("");
  const [allNames, setAllNames] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isResetting, setIsResetting] = useState(false);

  const updateStateFromData = (newData) => {
    const initialNamesMap = {};
    if (newData.names_map) {
      for (let group in newData.names_map) {
        initialNamesMap[group] = [...newData.names_map[group]];
      }
      setNamesMap(initialNamesMap);
    } else {
      setNamesMap({});
    }

    if (newData.all_names) {
      setAllNames([...newData.all_names]);
    } else {
      // Extract allNames from data.companies
      const customerNameIndex = newData.companies[0].indexOf(
        newData.customer_name_header
      );
      const allCompanyNames = newData.companies
        .slice(1)
        .map((row) => row[customerNameIndex]);
      setAllNames(allCompanyNames);
    }

    const assignedCompanies = new Set();
    for (let group in initialNamesMap) {
      initialNamesMap[group].forEach((company) =>
        assignedCompanies.add(company)
      );
    }

    setUnassignedCompanies(
      allNames
        .filter((company) => !assignedCompanies.has(company))
        .sort((a, b) => a.localeCompare(b)) // Sort alphabetically
    );
  };

  useEffect(() => {
    updateStateFromData(data);
  }, [data]);

  const processData = () => {
    if (
      !data ||
      !data.companies ||
      !data.aging_data ||
      !data.total_col ||
      !data.customer_name_header
    ) {
      console.log("Data is missing required properties");
      return;
    }

    const { companies, aging_data, total_col, customer_name_header } = data;
    if (
      companies.length === 0 ||
      aging_data.length === 0 ||
      total_col.length === 0
    ) {
      console.log("One or more arrays are empty");
      return;
    }

    const customerNameIndex = companies[0].indexOf(customer_name_header);

    const companyData = companies.slice(1).map((companyRow, index) => {
      const customerName = companyRow[customerNameIndex];
      const totalValue = total_col[index + 1];
      return { customerName, totalValue };
    });

    // Map companies to groups
    const companyToGroupMap = {};
    for (const group in namesMap) {
      for (const company of namesMap[group]) {
        companyToGroupMap[company] = group;
      }
    }

    // Aggregate totals per group
    const groupTotals = {};
    for (const { customerName, totalValue } of companyData) {
      const group = companyToGroupMap[customerName] || customerName;
      if (!groupTotals[group]) {
        groupTotals[group] = 0;
      }
      groupTotals[group] += totalValue;
    }

    // Convert groupTotals to array
    const groupTotalsArray = Object.keys(groupTotals).map((group) => ({
      name: group,
      value: groupTotals[group],
    }));

    // Sort groupTotalsArray by value descending
    groupTotalsArray.sort((a, b) => b.value - a.value);

    // Take top N entries
    const topNData = groupTotalsArray.slice(0, topN);

    // Compute total sum
    const totalSum = groupTotalsArray.reduce(
      (sum, item) => sum + item.value,
      0
    );
    setOverallTotal(totalSum);

    // Compute chartData
    const chartData = topNData.map((item) => ({
      name: item.name,
      value: item.value,
      percentage: ((item.value / totalSum) * 100).toFixed(2),
    }));
    setChartData(chartData);

    // Prepare tableData
    const headers = ["Group/Company", "Total Value"];
    const tableRows = topNData.map((item) => [item.name, item.value]);
    setTableData([headers, ...tableRows]);
  };

  useEffect(() => {
    processData();
  }, [data, topN, namesMap]);

  const handleTopNChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setTopN(value > 0 ? value : 1);
  };

  const handleCompanyUnassign = (company, group) => {
    setNamesMap((prevNamesMap) => {
      const newNamesMap = { ...prevNamesMap };
      newNamesMap[group] = newNamesMap[group].filter((c) => c !== company);
      if (newNamesMap[group].length === 0) {
        delete newNamesMap[group];
      }
      return newNamesMap;
    });
    setUnassignedCompanies((prev) =>
      [...prev, company].sort((a, b) => a.localeCompare(b))
    );
  };

  const handleCompanyAssign = (company, newGroup) => {
    setNamesMap((prevNamesMap) => {
      const newNamesMap = { ...prevNamesMap };
      if (!newNamesMap[newGroup]) {
        newNamesMap[newGroup] = [];
      }
      newNamesMap[newGroup] = [...newNamesMap[newGroup], company];
      return newNamesMap;
    });
    setUnassignedCompanies((prev) =>
      prev.filter((c) => c !== company).sort((a, b) => a.localeCompare(b))
    );
  };

  const handleAddNewGroup = () => {
    const trimmedName = newGroupName.trim();
    if (trimmedName !== "" && !namesMap.hasOwnProperty(trimmedName)) {
      setNamesMap((prevNamesMap) => ({
        ...prevNamesMap,
        [trimmedName]: [],
      }));
      setNewGroupName("");
      setShowNewGroupInput(false);
    }
  };

  const handleUpdateGroupings = async () => {
    setIsUpdating(true);
    try {
      const response = await fetch(getApiUrl("/api/set_exam_extra"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          exam_id: examID,
          extra_name: extraName,
          extra_data: namesMap,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update groupings");
      }

      const result = await response.json();
      console.log("Groupings updated successfully:", result);

      if (result.results && result.results.data) {
        console.log("New data after update:", result.results.data);
        updateStateFromData(result.results.data);
      }
    } catch (error) {
      console.error("Error updating groupings:", error);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleResetGroupings = async () => {
    setIsResetting(true);
    try {
      const response = await fetch(getApiUrl("/api/set_exam_extra"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          exam_id: examID,
          extra_name: extraName,
          extra_data: null,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to reset groupings");
      }

      const result = await response.json();
      console.log("Groupings reset successfully:", result);

      if (result.results && result.results.data) {
        console.log("New data after reset:", result.results.data);
        updateStateFromData(result.results.data);
      }
    } catch (error) {
      console.error("Error resetting groupings:", error);
    } finally {
      setIsResetting(false);
    }
  };

  const groupNames = Object.keys(namesMap);

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = outerRadius * 1.15;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${chartData[index].percentage}%`}
      </text>
    );
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="bg-white border border-gray-300 p-2 shadow-md">
          <p className="font-bold">{data.name}</p>
          <p>Value: {data.value.toLocaleString()}</p>
          <p>Percentage of Total: {data.percentage}%</p>
        </div>
      );
    }
    return null;
  };

  if (!tableData.length) return null;

  return (
    <div>
      <div className="flex flex-col md:flex-row items-start space-y-4 md:space-y-0 md:space-x-4">
        <div className="w-full md:w-auto border border-gray-300 rounded-lg p-2 flex items-center whitespace-nowrap">
          <label
            htmlFor="topN"
            className="text-sm font-medium text-gray-700 mr-2"
          >
            Top companies count:
          </label>
          <input
            type="number"
            id="topN"
            name="topN"
            value={topN}
            onChange={handleTopNChange}
            className="w-20 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            min="1"
          />
        </div>

        {/* Buttons for updating and resetting groupings */}
        <div className="flex">
          <button
            onClick={handleUpdateGroupings}
            className={`bg-blue-500 text-white px-4 py-2 rounded mr-2 ${
              isUpdating || isResetting ? "cursor-not-allowed opacity-50" : ""
            }`}
            disabled={isUpdating || isResetting}
          >
            {isUpdating ? "Processing..." : "Update Groupings"}
          </button>
          <button
            onClick={handleResetGroupings}
            className={`bg-red-500 text-white px-4 py-2 rounded ${
              isUpdating || isResetting ? "cursor-not-allowed opacity-50" : ""
            }`}
            disabled={isUpdating || isResetting}
          >
            {isResetting ? "Processing..." : "Reset Groupings"}
          </button>
        </div>

        {/* Company Groupings UI */}
        <div className="w-full md:w-1/2">
          <h2 className="text-xl font-bold mb-4 mt-4">Company Groupings</h2>
          {groupNames.map((group) => (
            <div key={group} className="mb-6">
              <h3 className="font-semibold">{group}</h3>
              <ul className="ml-4">
                {namesMap[group].map((company) => (
                  <li key={company} className="flex items-center my-2">
                    <span>{company}</span>
                    <button
                      onClick={() => handleCompanyUnassign(company, group)}
                      className="text-red-500 ml-2"
                      title="Unassign from group"
                    >
                      x
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          ))}

          {/* Add New Group button and input */}
          <button
            onClick={() => setShowNewGroupInput(true)}
            className="bg-green-500 text-white px-4 py-2 rounded mb-4"
          >
            Add New Group
          </button>
          {showNewGroupInput && (
            <div className="mb-4">
              <input
                type="text"
                value={newGroupName}
                onChange={(e) => setNewGroupName(e.target.value)}
                placeholder="Enter new group name"
                className="border rounded p-2 mr-2"
              />
              <button
                onClick={handleAddNewGroup}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                Add Group
              </button>
            </div>
          )}

          {/* Display unassigned companies, if any */}
          {unassignedCompanies.length > 0 && (
            <div className="mb-6">
              <h3 className="font-semibold">Unassigned</h3>
              <ul className="ml-4">
                {unassignedCompanies.map((company) => (
                  <li key={company} className="flex items-center my-2">
                    <span>{company}</span>
                    <select
                      value=""
                      onChange={(e) =>
                        handleCompanyAssign(company, e.target.value)
                      }
                      className="border rounded p-1 ml-2"
                    >
                      <option value="" disabled>
                        Assign to group
                      </option>
                      {groupNames.map((g) => (
                        <option key={g} value={g}>
                          {g}
                        </option>
                      ))}
                    </select>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>

      {/* Table and Chart */}
      <div className="flex flex-col md:flex-row items-start space-y-4 md:space-y-0 md:space-x-4 mt-4">
        <div className="w-full md:w-1/2">
          <ArrayTable data={tableData} />
        </div>
        <div className="w-full md:w-1/2 h-[600px] border border-gray-300 rounded-lg p-4">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={chartData}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={200}
                fill="#8884d8"
                dataKey="value"
                isAnimationActive={true}
                animationBegin={0}
                animationDuration={400}
              >
                {chartData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltip />} />
              <Legend
                layout="horizontal"
                verticalAlign="bottom"
                align="center"
                wrapperStyle={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default ConcentrationAnalysisDisplay;
