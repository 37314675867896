import React from "react";
import CustomTable from "./CustomTable";
  
function TableFromListOfDicts({ listOfDicts, callout, title }) {
  if (!listOfDicts || listOfDicts.length === 0) {
    return null;
  }

  // Preserve the original order of headers
  const headers = Object.keys(listOfDicts[0]);

  const rows = listOfDicts.map((dict) => {
    return headers.reduce((acc, header) => {
      acc[header] = dict[header];
      return acc;
    }, {});
  });

  return (
    <div className="inline-block mx-2 mb-6">
      <div className="w-[calc(50vw-375px)] min-w-[600px] max-w-[800px] overflow-hidden">
        <h3 className="text-lg font-bold p-2">{title}</h3>
        <div className="flex justify-center">
          <CustomTable
            rows={rows}
            orderedKeys={headers}
            excludedKeys={[]}
            mergeEnabled={false}
            headerMappings={Object.fromEntries(
              headers.map((header) => [header, header])
            )}
            callout={callout}
          />
        </div>
      </div>
    </div>
  );
}

export default TableFromListOfDicts;
