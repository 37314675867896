import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "./Button";
import logoImage from "../assets/FE360_NBG_square.png"; // Adjust the path as needed
import getApiUrl from "../config";

const Header = ({ buttons }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const controlNavbar = () => {
    if (window) {
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => window.removeEventListener("scroll", controlNavbar);
  }, [lastScrollY]);

  const logoutURL = getApiUrl("/api/logout");
  const LogoutButton = () => (
    <Button text="Logout" url={logoutURL} variant="base" />
  );

  return (
    <header
      className={`fixed bg-FEBlue top-0 w-full z-[9999] shadow-md transition-transform duration-300 ${
        !isVisible && "-translate-y-full"
      }`}
    >
      <div className="mx-auto flex h-16 items-center justify-between pl-4 pr-8 sm:pl-3 sm:pr-6 lg:pl-4 lg:pr-8">
        <Link to="/dashboard" className="flex items-center">
          <img src="/FE360_NBG_square.png" alt="Logo" className="h-12" />
          <h1 className="text-white text-xl font-bold ml-4 whitespace-nowrap">
            ExamFlow
          </h1>
        </Link>
        <div className="flex-1 flex items-center gap-4 ml-8">
          <nav aria-label="Global" className="flex items-center gap-6">
            {buttons.map((button, index) => (
              <div
                key={index}
                className="text-gray-100 transition hover:text-teal-600"
              >
                {button}
              </div>
            ))}
          </nav>
          <div className="ml-auto flex items-center gap-4">
            <LogoutButton />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
