// utils.js

export function extractRows(data) {
  if (!Array.isArray(data)) {
    console.error("extractRows received non-array data:", data);
    return [];
  }
  return data.map((item) => {
    const newItem = { ...item };
    if (newItem.coords) delete newItem.coords;
    if (newItem["Match Value"] && Object.keys(newItem).length === 1) {
      return newItem;
    }
    return newItem;
  });
}

export function processData(data) {
  const tables = {};

  const processNestedTables = (prefix, nestedData, topLevelKey) => {
    if (typeof nestedData !== "object" || nestedData === null) {
      console.error(`Invalid nestedData for ${prefix}:`, nestedData);
      return;
    }
    Object.entries(nestedData).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        const extractedRows = extractRows(value);
        if (extractedRows.length > 0) {
          tables[`${prefix}.${key}`] = { rows: extractedRows, topLevelKey };
        }
      } else if (typeof value === "object" && value !== null) {
        processNestedTables(`${prefix}.${key}`, value, topLevelKey);
      }
    });
  };

  if (typeof data !== "object" || data === null) {
    console.error("Invalid data structure:", data);
    return tables;
  }

  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === "object" && value !== null) {
      processNestedTables(key, value, key);
    }
  });
  return tables;
}

export function getTableName(key) {
  if (!key) {
    console.error("getTableName received undefined key");
    return "Unknown Table";
  }
  const parts = key.split(".");

  const transformWord = (word) => {
    const strippedWord = word.trim();
    return (
      strippedWord.charAt(0).toUpperCase() + strippedWord.slice(1).toLowerCase()
    );
  };

  if (parts[1] === "identifier_table") {
    return "Document Identification";
  } else if (parts[1] === "match_section") {
    return "Document Comparison";
  } else if (parts[1] === "details_tables") {
    if (parts[2] === "MATCH") {
      const words = parts[3].split(":");
      const transformedWords = words.map((word) =>
        word.split("_").map(transformWord).join(" ")
      );
      return `${transformedWords.join(": ")}: Details`;
    } else if (parts[2] === "LOOKUP") {
      const words = parts[4].split("_");
      const transformedPart = words.map(transformWord).join(" ");
      return `${parts[3]}: ${transformedPart}`;
    }
  } else if (parts[1] === "lookup_comparisons") {
    return `${parts[2]}: Lookups`;
  } else if (parts[1] === "function_evals") {
    const words = parts[2].split("_");
    const transformedWords = words.map(transformWord).join(" ");
    return transformedWords;
  }
  return key;
}

export function getUniqueLetter(table) {
  if (!table || !table.rows || table.rows.length === 0) return "Z";
  const firstRow = table.rows[0];
  return firstRow["Unique Letter"] || firstRow["#"] || "Z";
}

export function orderTables(groupTables) {
  if (!Array.isArray(groupTables)) {
    console.error("orderTables received non-array input:", groupTables);
    return [];
  }
  const orderedTables = [];
  const detailsTables = {};
  const lookupTables = {};
  const functionTables = [];

  groupTables.forEach((table) => {
    if (!table || !table.key) {
      console.error("Invalid table object:", table);
      return;
    }
    const keyParts = table.key.split(".");

    if (keyParts[1] === "identifier_table") {
      orderedTables.unshift(table);
    } else if (keyParts[1] === "match_section") {
      orderedTables.push(table);
    } else if (keyParts[1] === "details_tables" && keyParts[2] === "MATCH") {
      detailsTables[keyParts[3]] = table;
    } else if (keyParts[1] === "lookup_comparisons") {
      lookupTables[keyParts[2]] = table;
    } else if (keyParts[1] === "details_tables" && keyParts[2] === "LOOKUP") {
      if (!detailsTables[keyParts[3]]) {
        detailsTables[keyParts[3]] = {};
      }
      detailsTables[keyParts[3]][keyParts[4]] = table;
    } else if (keyParts[1] === "function_evals") {
      functionTables.push(table);
    }
  });

  // Add match details
  Object.values(detailsTables).forEach((table) => {
    if (!Array.isArray(table)) {
      orderedTables.push(table);
    }
  });

  // Sort lookups by unique letter
  const sortedLookups = Object.entries(lookupTables).sort((a, b) => {
    const letterA = getUniqueLetter(a[1]);
    const letterB = getUniqueLetter(b[1]);
    return letterA.localeCompare(letterB);
  });

  // Add sorted lookups and their details
  sortedLookups.forEach(([key, table]) => {
    orderedTables.push(table);
    if (detailsTables[key]) {
      Object.values(detailsTables[key]).forEach((detailTable) => {
        orderedTables.push(detailTable);
      });
    }
  });

  // Add function tables at the end
  orderedTables.push(...functionTables);

  return orderedTables;
}
