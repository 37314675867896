import React, { useRef, useState, useCallback } from "react";
import { ChevronUp, ChevronDown, X } from "lucide-react";
import getApiUrl from "../config";

const SamplesTable = ({
  examData,
  currentTest,
  handleCellClick,
  handleUpdateExamData,
}) => {
  const tableRef = useRef(null);
  const [draggedItem, setDraggedItem] = useState(null);
  const [isOpen, setIsOpen] = useState(true);
  const [localExamData, setLocalExamData] = useState(examData);
  const dropHandledRef = useRef(false);
  const [hoveredDoc, setHoveredDoc] = useState(null);
  const [confirmationDialog, setConfirmationDialog] = useState({
    isOpen: false,
    doc: null,
    identifier: null,
  });
  

  const updateLocalAndParentState = useCallback(
    (newExamData) => {
      setLocalExamData(newExamData);
      handleUpdateExamData(newExamData);
    },
    [handleUpdateExamData]
  );

  const moveDocument = async (dragData, newType, newRowId) => {
    try {
      const updatedExamData = JSON.parse(JSON.stringify(localExamData));
      let newDocHash;

      if (dragData.oldRowId) {
        const oldSampleId = dragData.oldRowId;
        newDocHash = newRowId
          ? newRowId + dragData.docHash.slice(oldSampleId.length)
          : dragData.docHash;

        Object.keys(updatedExamData[currentTest].samples).forEach(
          (sampleId) => {
            updatedExamData[currentTest].samples[sampleId] = updatedExamData[
              currentTest
            ].samples[sampleId].filter(
              (doc) => doc.doc_hash !== dragData.docHash
            );
          }
        );
      } else {
        newDocHash = newRowId
          ? newRowId + "-" + dragData.docHash
          : dragData.docHash;
      }

      if (newRowId) {
        const movedDoc = {
          doc_hash: newDocHash,
          doc_type: newType,
          file_name: dragData.fileName,
          file_id: dragData.fileId,
          image: dragData.image,
        };

        if (!updatedExamData[currentTest].samples[newRowId]) {
          updatedExamData[currentTest].samples[newRowId] = [];
        }
        updatedExamData[currentTest].samples[newRowId].push(movedDoc);
      }

      updateLocalAndParentState(updatedExamData);

      const response = await fetch(getApiUrl("/api/move_doc"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          current_test: currentTest,
          doc_hash: newDocHash,
          new_type: newType,
          new_row_id: newRowId,
          old_type: dragData.oldType,
          old_row_id: dragData.oldRowId,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to move document");
      }

      const result = await response.json();
      console.log("Document moved successfully:", result);
      return newDocHash;
    } catch (error) {
      console.error("Error moving document:", error);
      updateLocalAndParentState(examData);
      return null;
    }
  };

  const handleDragStart = (e, doc, rowIdentifier) => {
    dropHandledRef.current = false;
    const dragData = {
      currentTest,
      docType: doc.doc_type,
      fileName: doc.file_name,
      docHash: doc.doc_hash,
      fileId: doc.file_id,
      image: doc.image,
      oldType: doc.doc_type,
      oldRowId: rowIdentifier,
    };
    setDraggedItem(dragData);
    e.dataTransfer.setData("text/plain", JSON.stringify(dragData));
  };

  const handleDrop = async (e, newRowIdentifier) => {
    e.preventDefault();
    dropHandledRef.current = true;
    const dragData = JSON.parse(e.dataTransfer.getData("text/plain"));
    console.log("Dragged file info:", dragData);

    const newCellType = e.target.closest("td").getAttribute("data-doc-type");
    console.log("Dragged file dropped on cell type:", newCellType);

    await moveDocument(dragData, newCellType, newRowIdentifier);
    setDraggedItem(null);
  };

  const handleDragEnd = (e, doc, rowIdentifier) => {
    if (!dropHandledRef.current) {
      removeDocument(doc, rowIdentifier);
    }
  };

  const removeDocument = async (doc, rowIdentifier) => {
    try {
      const updatedExamData = JSON.parse(JSON.stringify(localExamData));

      updatedExamData[currentTest].samples[rowIdentifier] = updatedExamData[
        currentTest
      ].samples[rowIdentifier].filter((d) => d.doc_hash !== doc.doc_hash);

      if (updatedExamData[currentTest].samples[rowIdentifier].length === 0) {
        delete updatedExamData[currentTest].samples[rowIdentifier];
      }

      updateLocalAndParentState(updatedExamData);

      await moveDocument(
        {
          currentTest,
          docType: doc.doc_type,
          fileName: doc.file_name,
          docHash: doc.doc_hash,
          fileId: doc.file_id,
          image: doc.image,
          oldType: doc.doc_type,
          oldRowId: rowIdentifier,
        },
        null,
        null
      );

      console.log("Document removed successfully");
    } catch (error) {
      console.error("Error removing document:", error);
      updateLocalAndParentState(examData);
    }
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleRemoveClick = (e, doc, identifier) => {
    e.stopPropagation();
    setConfirmationDialog({ isOpen: true, doc, identifier });
  };

  const handleConfirmRemove = () => {
    if (confirmationDialog.doc && confirmationDialog.identifier) {
      removeDocument(confirmationDialog.doc, confirmationDialog.identifier);
    }
    setConfirmationDialog({ isOpen: false, doc: null, identifier: null });
  };

  const handleCancelRemove = () => {
    setConfirmationDialog({ isOpen: false, doc: null, identifier: null });
  };

  if (!examData || !currentTest || !examData[currentTest]) {
    return null;
  }

  const samples = examData[currentTest].samples;
  if (!samples || Object.keys(samples).length === 0) {
    return (
      <div className="text-center py-8 text-gray-500">
        No samples completed for this test.
      </div>
    );
  }

  const sortedSamples = Object.entries(samples).sort((a, b) =>
    a[0].localeCompare(b[0], undefined, { numeric: true, sensitivity: "base" })
  );

  const columns =
    currentTest === "invoice"
      ? [
          "Identifier",
          "INVOICE",
          "PURCHASE",
          "PAYMENT",
          "SHIPPING",
          "REMITTANCE",
        ]
      : currentTest === "cash"
      ? ["Identifier", "INVOICE", "PAYMENT", "BANK", "CASH"]
      : currentTest === "vendor"
      ? ["Identifier", "INVOICE", "PAYMENT", "BANK", "DISBURSEMENT"]
      : currentTest === "ar_ap_analysis"
      ? ["Identifier", "AR_AGING", "AP_AGING"]
      : currentTest === "gross_profit"
      ? ["Identifier", "INVOICE", "INVENTORY"]
      : ["Identifier"];

  return (
    <div className="m-8">
      <div className="flex items-center mb-4">
        <h2 className="text-xl font-semibold mr-2">Samples Table</h2>
        <button onClick={toggleOpen} className="focus:outline-none">
          {isOpen ? (
            <ChevronUp className="w-6 h-6" />
          ) : (
            <ChevronDown className="w-6 h-6" />
          )}
        </button>
      </div>
      {isOpen && (
        <div
          className="overflow-x-auto rounded-lg border border-gray-200"
          ref={tableRef}
          onDragOver={(e) => e.preventDefault()}
        >
          <table className="w-full table-fixed divide-y divide-gray-200 bg-white text-sm">
            <thead className="bg-gray-50">
              <tr>
                {columns.map((col) => (
                  <th
                    key={col}
                    className="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                    style={{
                      minWidth: col === "Identifier" ? "100px" : "200px",
                    }}
                  >
                    {col}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {sortedSamples.map(([identifier, docs]) => (
                <tr key={identifier}>
                  <td className="px-4 py-2 whitespace-nowrap font-medium border-r text-center text-gray-900">
                    {identifier}
                  </td>
                  {columns.slice(1).map((docType) => {
                    const matchingDocs = docs.filter(
                      (d) => d.doc_type === docType
                    );
                    const isHighlighted = matchingDocs.length > 1;

                    return (
                      <td
                        key={docType}
                        data-doc-type={docType}
                        className={`px-4 py-2 whitespace-normal text-gray-700 border-r ${
                          isHighlighted ? "bg-gray-100" : ""
                        }`}
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={(e) => handleDrop(e, identifier)}
                      >
                        {matchingDocs.length > 0 ? (
                          <div className="flex flex-col items-center justify-center">
                            {matchingDocs.map((doc, index) => (
                              <div
                                key={index}
                                className="mb-4 last:mb-0 cursor-pointer w-full"
                                onClick={() => handleCellClick(doc.file_id)}
                                draggable
                                onDragStart={(e) =>
                                  handleDragStart(e, doc, identifier)
                                }
                                onMouseEnter={() => setHoveredDoc(doc.doc_hash)}
                                onMouseLeave={() => setHoveredDoc(null)}
                              >
                                <div className="text-center break-words">
                                  {doc.file_name}
                                </div>
                                <div className="inline-grid grid-cols-1 grid-rows-1 justify-items-center w-full">
                                  <img
                                    src={`data:image/png;base64,${doc.image}`}
                                    alt={doc.file_name}
                                    className="w-48 h-auto object-cover mt-2 col-start-1 row-start-1"
                                  />
                                  {hoveredDoc === doc.doc_hash && (
                                    <div className="col-start-1 row-start-1 justify-self-end self-start pointer-events-none">
                                      <button
                                        className="bg-red-500 text-white rounded-full p-1 pointer-events-auto m-2"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveClick(e, doc, identifier);
                                        }}
                                      >
                                        <X className="w-4 h-4" />
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : null}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {confirmationDialog.isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg">
            <h3 className="text-lg font-semibold mb-4">Confirm Removal</h3>
            <p className="mb-4">Remove this document from the sample?</p>
            <div className="flex justify-end">
              <button
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded mr-2"
                onClick={handleCancelRemove}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-red-500 text-white rounded"
                onClick={handleConfirmRemove}
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SamplesTable;