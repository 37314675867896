import React from "react";

const ResultsSelector = ({ keys, selectedKey, onSelectKey, samplesData }) => {
  const sortedKeys = [...keys].sort((a, b) => a.localeCompare(b));

  const handlePrev = () => {
    const currentIndex = sortedKeys.indexOf(selectedKey);
    if (currentIndex > 0) {
      onSelectKey(sortedKeys[currentIndex - 1]);
    }
  };

  const handleNext = () => {
    const currentIndex = sortedKeys.indexOf(selectedKey);
    if (currentIndex < sortedKeys.length - 1) {
      onSelectKey(sortedKeys[currentIndex + 1]);
    }
  };

  return (
    <ol className="flex flex-wrap justify-center gap-2 text-xs font-medium my-4">
      <li>
        <button
          onClick={handlePrev}
          className="inline-flex h-8 w-8 items-center justify-center rounded border border-gray-300 bg-white text-gray-700 hover:bg-gray-50 rtl:rotate-180"
          disabled={sortedKeys.indexOf(selectedKey) === 0}
        >
          <span className="sr-only">Previous Page</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-3 w-3"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </li>
      {sortedKeys.map((key) => (
        <li key={key}>
          <button
            onClick={() => onSelectKey(key)}
            className={`rounded border px-3 h-8 min-w-[2rem] ${
              selectedKey === key
                ? "border-FEBlue bg-FEBlue text-white"
                : "border-gray-300 bg-white text-gray-700 hover:bg-gray-50"
            } text-center leading-8 whitespace-nowrap`}
            title={samplesData[key]?.text}
          >
            {key}
          </button>
        </li>
      ))}
      <li>
        <button
          onClick={handleNext}
          className="inline-flex h-8 w-8 items-center justify-center rounded border border-gray-300 bg-white text-gray-700 hover:bg-gray-50 rtl:rotate-180"
          disabled={sortedKeys.indexOf(selectedKey) === sortedKeys.length - 1}
        >
          <span className="sr-only">Next Page</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-3 w-3"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </li>
    </ol>
  );
};

export default ResultsSelector;