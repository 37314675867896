// useReportData.js
import { useState, useEffect } from "react";
import { processData } from "./utils";

function useReportData(initialData, testType) {
  const [reportData, setReportData] = useState(null);
  const [tables, setTables] = useState({});

  useEffect(() => {
    if (initialData) {
      setReportData(initialData);
      if (["invoice", "cash", "vendor", "gross_profit"].includes(testType)) {
        const processedData = processData(initialData);
        setTables(processedData);
      }
    }
  }, [initialData, testType]);

  return { reportData, tables };
}

export default useReportData;
