import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./components/Header";
import getApiUrl from "./config";

const buttonBaseClass =
  "inline-block rounded px-8 py-3 text-sm font-medium transition hover:scale-110 hover:shadow-xl focus:outline-none focus:ring";
const buttonVariantClass = {
  base: `bg-FEOrange text-white active:bg-FEOrangeActive`,
  border: `border border-current text-FEOrange active:text-FEOrangeActive`,
  remove: `bg-red-500 text-white active:bg-red-600`,
  cancel: `bg-gray-500 text-white active:bg-gray-600`,
};

const Button = ({ text, onClick, variant = "base" }) => {
  return (
    <button
      className={`${buttonBaseClass} ${buttonVariantClass[variant]}`}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

const ClientUsersManagement = ({ clientId }) => {
  const navigate = useNavigate();
  const [clientInfo, setClientInfo] = useState({ ClientName: "", Seats: 0 });
  const [users, setUsers] = useState([]);
  const [newEmails, setNewEmails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [removedUsers, setRemovedUsers] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    checkAuth();
  }, [navigate]);

  const checkAuth = async () => {
    try {
      const response = await fetch(getApiUrl("/api/check_auth"), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (!data.authenticated) {
        navigate("/");
      } else {
        setIsAuthenticated(true);
        fetchClientInfo();
        fetchClientUsers();
      }
    } catch (err) {
      setError("Authentication failed");
      navigate("/");
    }
  };

  const fetchClientInfo = async () => {
    try {
      const response = await fetch(
        getApiUrl(`/api/get_client_info?client_id=${clientId}`)
      );
      const data = await response.json();
      setClientInfo(data);
      console.log(data);
    } catch (err) {
      setError("Failed to fetch client info");
    }
  };

  const fetchClientUsers = async () => {
    try {
      const response = await fetch(
        getApiUrl(`/api/get_client_users?client_id=${clientId}`)
      );
      const data = await response.json();
      setUsers(data.users);
      
    } catch (err) {
      setError("Failed to fetch users");
    } finally {
      setIsLoading(false);
    }
  };

  const handleNameChange = (e) => {
    setClientInfo({ ...clientInfo, ClientName: e.target.value });
  };

  const handleUserRoleChange = (email) => {
    const updatedUsers = users.map((user) =>
      user.Email === email
        ? { ...user, UserRole: user.UserRole === "admin" ? "user" : "admin" }
        : user
    );

    const adminCount = updatedUsers.filter(
      (user) => user.UserRole === "admin" && !removedUsers.includes(user.Email)
    ).length;

    if (adminCount === 0) {
      alert("There must always be at least one admin.");
      return;
    }

    setUsers(updatedUsers);
  };

  const handleRemoveUser = (email) => {
    if (removedUsers.includes(email)) {
      setRemovedUsers(removedUsers.filter((e) => e !== email));
    } else {
      const updatedRemovedUsers = [...removedUsers, email];
      const remainingAdmins = users.filter(
        (user) =>
          user.UserRole === "admin" && !updatedRemovedUsers.includes(user.Email)
      ).length;

      if (remainingAdmins === 0) {
        alert(
          "You cannot remove the last admin. There must always be at least one admin."
        );
        return;
      }

      setRemovedUsers(updatedRemovedUsers);
    }
  };

  const handleAddEmail = () => {
    if (users.length + newEmails.length < clientInfo.Seats) {
      setNewEmails([...newEmails, ""]);
    }
  };

  const handleNewEmailChange = (index, value) => {
    const updatedEmails = [...newEmails];
    updatedEmails[index] = value;
    setNewEmails(updatedEmails);
  };

  const handleRemoveNewEmail = (index) => {
    setNewEmails(newEmails.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    setShowConfirmModal(true);
  };

  const confirmSubmit = async () => {
    const updatedData = {
      updated_name: clientInfo.ClientName,
      admin_emails: users
        .filter(
          (user) =>
            user.UserRole === "admin" && !removedUsers.includes(user.Email)
        )
        .map((user) => user.Email),
      removed_emails: removedUsers,
      new_emails: newEmails.filter((email) => email.trim() !== ""),
    };

    try {
      const response = await fetch(getApiUrl("/api/update_team"), {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(updatedData),
      });
      if (response.ok) {
        fetchClientInfo();
        fetchClientUsers();
        setNewEmails([]);
        setRemovedUsers([]);
      } else {
        setError("Failed to update team");
      }
    } catch (err) {
      setError("An error occurred while updating the team");
    } finally {
      setShowConfirmModal(false);
    }
    navigate("/dashboard");
  };
    const buttons = [
      <button
        key="dashboard"
        onClick={() => navigate("/dashboard")}
        className="text-white hover:text-FEOrange transition"
      >
        Dashboard
      </button>,
    ];

  return (
    <div className="bg-white min-h-screen">
      <Header buttons={buttons} />
      {isAuthenticated === null || isLoading ? (
        <div className="flex justify-center items-center h-screen">
          Loading...
        </div>
      ) : error ? (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-16"
          role="alert"
        >
          <strong className="font-bold">Error!</strong>
          <span className="block sm:inline"> {error}</span>
        </div>
      ) : (
        <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 mt-16">
          <div className="px-4 py-6 sm:px-0">
            <div className="flex flex-col gap-4 p-4 border rounded shadow-md">
              <h2 className="text-2xl font-bold mb-4">Company Information</h2>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Company Name:
                </label>
                <input
                  type="text"
                  value={clientInfo.ClientName}
                  onChange={handleNameChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div className="mb-4">
                <p className="text-sm text-gray-600">
                  Available Seats: {clientInfo.Seats}
                </p>
                <p className="text-sm text-gray-600">
                  Used Seats: {users.length}
                </p>
              </div>
            </div>

            <div className="flex flex-col gap-4 p-4 border rounded shadow-md mt-4">
              <h2 className="text-2xl font-bold mb-4">Team Members</h2>
              {users.map((user, index) => (
                <div
                  key={index}
                  className={`flex items-center py-2 px-4 rounded ${
                    removedUsers.includes(user.Email) ? "bg-red-100" : ""
                  }`}
                >
                  {user.Picture ? (
                    <img
                      src={user.Picture}
                      alt={user.Name || user.Email}
                      className="w-10 h-10 rounded-full mr-2 object-cover"
                    />
                  ) : (
                    <div className="w-10 h-10 bg-gray-200 rounded-full mr-2 flex items-center justify-center">
                      <span className="text-gray-500 font-bold">
                        {(user.Name || user.Email).charAt(0).toUpperCase()}
                      </span>
                    </div>
                  )}
                  <div className="flex-grow">
                    <p className="text-sm font-medium text-gray-900">
                      {user.Name || user.Email}
                    </p>
                    {user.Name && (
                      <p className="text-sm text-gray-500">{user.Email}</p>
                    )}
                  </div>
                  {!removedUsers.includes(user.Email) && (
                    <label className="inline-flex items-center mr-2">
                      <input
                        type="checkbox"
                        checked={user.UserRole === "admin"}
                        onChange={() => handleUserRoleChange(user.Email)}
                        className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                      />
                      <span className="ml-2 text-sm text-gray-600">Admin</span>
                    </label>
                  )}
                  <Button
                    text={
                      removedUsers.includes(user.Email)
                        ? "Cancel Remove"
                        : "Remove"
                    }
                    onClick={() => handleRemoveUser(user.Email)}
                    variant={
                      removedUsers.includes(user.Email) ? "cancel" : "remove"
                    }
                  />
                </div>
              ))}
            </div>

            <div className="flex flex-col gap-4 p-4 border rounded shadow-md mt-4">
              <h2 className="text-2xl font-bold mb-4">Add New Team Members</h2>
              {newEmails.map((email, index) => (
                <div key={index} className="flex items-center mb-2">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) =>
                      handleNewEmailChange(index, e.target.value)
                    }
                    placeholder="Enter email"
                    className="flex-grow p-2 border rounded mr-2"
                  />
                  <Button
                    text="Cancel"
                    onClick={() => handleRemoveNewEmail(index)}
                    variant="cancel"
                  />
                </div>
              ))}
              {users.length + newEmails.length < clientInfo.Seats ? (
                <div className="flex justify-between mt-4">
                  <Button
                    text="Add Team Member"
                    onClick={handleAddEmail}
                    variant="base"
                  />
                </div>
              ) : (
                <p className="text-sm text-gray-600 mt-2">
                  No open seats remain.
                </p>
              )}
            </div>

            <div className="flex justify-between mt-4">
              <Button
                text="Cancel Changes"
                onClick={() => navigate("/dashboard")}
                variant="cancel"
              />
              <Button
                text="Submit Changes"
                onClick={handleSubmit}
                variant="base"
              />
            </div>
          </div>
        </main>
      )}

      {showConfirmModal && (
        <div
          className="fixed z-10 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-lg leading-6 font-medium text-gray-900"
                      id="modal-title"
                    >
                      Confirm Team Changes
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to submit these team changes?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse sm:justify-between">
                <Button text="Confirm" onClick={confirmSubmit} variant="base" />
                <Button
                  text="Cancel"
                  onClick={() => setShowConfirmModal(false)}
                  variant="cancel"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientUsersManagement;