// import { useState, useEffect } from "react";

// const useTestResults = (examData, resultsData, currentTest) => {
//   const [selectedKey, setSelectedKey] = useState(null);
//   const [testResultsData, setTestResultsData] = useState(null);

//   const testOrder = ["invoice", "cash", "vendor", "aging"];

//   useEffect(() => {
//     if (examData && Object.keys(examData).length > 0) {
//       const firstAvailableTest =
//         testOrder.find((test) => examData[test]) || Object.keys(examData)[0];
//       setCurrentTest(firstAvailableTest);
//     }
//   }, [examData]);

//   useEffect(() => {
//     if (resultsData && currentTest) {
//       const testResults = resultsData[currentTest];
//       if (testResults && Object.keys(testResults).length > 0) {
//         const firstKey = Object.keys(testResults)[0];
//         setSelectedKey(firstKey);
//         setTestResultsData(testResults[firstKey]);
//       } else {
//         setSelectedKey(null);
//         setTestResultsData(null);
//       }
//     }
//   }, [resultsData, currentTest]);

//   useEffect(() => {
//     if (resultsData && currentTest && selectedKey) {
//       setTestResultsData(resultsData[currentTest][selectedKey]);
//     }
//   }, [resultsData, currentTest, selectedKey]);

//   return {
//     currentTest,
//     setCurrentTest,
//     selectedKey,
//     setSelectedKey,
//     testResultsData,
//   };
// };

// export default useTestResults;
// import { useState, useEffect } from "react";

// const useTestResults = (examData, resultsData, currentTest) => {
//   const [selectedKey, setSelectedKey] = useState(null);
//   const [testResultsData, setTestResultsData] = useState(null);

//   useEffect(() => {
//     if (resultsData && currentTest) {
//       const testResults = resultsData[currentTest];
//       if (testResults && Object.keys(testResults).length > 0) {
//         const firstKey = Object.keys(testResults)[0];
//         setSelectedKey(firstKey);
//         setTestResultsData(testResults[firstKey]);
//       } else {
//         setSelectedKey(null);
//         setTestResultsData(null);
//       }
//     }
//   }, [resultsData, currentTest]);

//   useEffect(() => {
//     if (resultsData && currentTest && selectedKey) {
//       setTestResultsData(resultsData[currentTest][selectedKey]);
//     }
//   }, [resultsData, currentTest, selectedKey]);

//   return {
//     selectedKey,
//     setSelectedKey,
//     testResultsData,
//   };
// };

// export default useTestResults;
import { useState, useEffect } from "react";

const useTestResults = (examData, resultsData, currentTest) => {
  const [selectedKey, setSelectedKey] = useState(null);
  const [testResultsData, setTestResultsData] = useState(null);

  useEffect(() => {
    if (resultsData && currentTest && resultsData[currentTest]) {
      const testResults = resultsData[currentTest];
      if (testResults && Object.keys(testResults).length > 0) {
        const firstKey = Object.keys(testResults)[0];
        setSelectedKey(firstKey);
        setTestResultsData(testResults[firstKey]);
      } else {
        setSelectedKey(null);
        setTestResultsData(null);
      }
    } else {
      setSelectedKey(null);
      setTestResultsData(null);
    }
  }, [resultsData, currentTest]);

  useEffect(() => {
    if (
      resultsData &&
      currentTest &&
      selectedKey &&
      resultsData[currentTest] &&
      resultsData[currentTest][selectedKey]
    ) {
      setTestResultsData(resultsData[currentTest][selectedKey]);
    } else {
      setTestResultsData(null);
    }
  }, [resultsData, currentTest, selectedKey]);

  return {
    selectedKey,
    setSelectedKey,
    testResultsData,
  };
};

export default useTestResults;