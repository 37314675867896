import React, { useEffect, useRef, useState } from "react";

const LoadingOverlay = ({ isLoading }) => {
  const outerCircleRef = useRef(null);
  const innerCircleRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    let timeoutId;
    if (isLoading) {
      const outerAngle = Math.random() * 360;
      const innerAngle = Math.random() * 360;
      if (outerCircleRef.current) {
        outerCircleRef.current.style.setProperty(
          "--start-angle",
          `${outerAngle}deg`
        );
      }
      if (innerCircleRef.current) {
        innerCircleRef.current.style.setProperty(
          "--start-angle",
          `${innerAngle}deg`
        );
      }
      timeoutId = setTimeout(() => {
        setIsVisible(true);
      }, 700);
    } else {
      setIsVisible(false);
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [isLoading]);

  if (!isLoading) return null;

  return (
    <div 
      className={`fixed inset-0 flex items-center justify-center z-50 transition-opacity duration-1000 ${
        isVisible ? 'opacity-100' : 'opacity-0'
      }`}
    >
      <div className="relative w-40 h-40">
        <div
          ref={outerCircleRef}
          className="w-40 h-40 rounded-full absolute inset-0"
          style={{
            border: "6px solid #034566",
            borderTopColor: "transparent",
            animation: "spin-slower 5.66s linear infinite",
            transform: "rotate(var(--start-angle, 0deg))",
          }}
        />
        <div
          ref={innerCircleRef}
          className="w-32 h-32 rounded-full absolute inset-0 m-auto"
          style={{
            border: "6px solid #fb8500",
            borderTopColor: "transparent",
            animation: "spin-slow 3.66s linear infinite",
            transform: "rotate(var(--start-angle, 0deg))",
          }}
        />
      </div>
      <style>
        {`
          @keyframes spin-slower {
            from { transform: rotate(var(--start-angle, 0deg)); }
            to { transform: rotate(calc(var(--start-angle, 0deg) + 360deg)); }
          }
          @keyframes spin-slow {
            from { transform: rotate(var(--start-angle, 0deg)); }
            to { transform: rotate(calc(var(--start-angle, 0deg) + 360deg)); }
          }
        `}
      </style>
    </div>
  );
};

export default LoadingOverlay;