import React from "react";

function ArrayTable({ data }) {
  try {
    if (!Array.isArray(data) || data.length === 0) return "error";
    const headers = data[0];
    const rows = data.slice(1);
    return (
      <div className="w-full overflow-x-auto">
        <div className="rounded-lg border border-gray-300 shadow-sm overflow-hidden">
          <table className="table-auto w-full divide-y-2 divide-gray-300 bg-white text-sm rounded-lg">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-300">
                {headers.map((header, index) => (
                  <th
                    key={index}
                    className="px-4 py-3 font-medium text-gray-900 text-left whitespace-normal break-words"
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-300">
              {rows.map((row, rowIndex) => (
                <tr key={rowIndex} className="divide-x divide-gray-300">
                  {row.map((cell, cellIndex) => (
                    <td
                      key={cellIndex}
                      className="px-4 py-3 text-gray-700 text-left whitespace-normal break-words"
                    >
                      {cell}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  } catch (error) {
    console.error("Error in ArrayTable:", error);
    return "error";
  }
}

export default ArrayTable;
