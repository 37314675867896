// Button.js
import React from "react";
import { buttonBaseClass, buttonVariantClass } from "./Styles";

const Button = ({ text, url, variant = "base" }) => {
  return (
    <a
      className={`${buttonBaseClass} ${buttonVariantClass[variant]}`}
      href={url}
    >
      {text}
    </a>
  );
};

export default Button;
