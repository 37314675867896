import React, { useState, useEffect } from "react";
import ArrayTable from "./ArrayTable";
import getApiUrl from "../../config";


const ContraAnalysisDisplay = ({ data, examID }) => {
  console.log("CONTRACONTRACONTRACONTRA", data);
  const [namesMap, setNamesMap] = useState({});
  const [unassignedCompanies, setUnassignedCompanies] = useState([]);
  const [showNewGroupInput, setShowNewGroupInput] = useState(false);
  const [newGroupName, setNewGroupName] = useState("");
  const [contraData, setContraData] = useState([]);
  const [allNames, setAllNames] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isResetting, setIsResetting] = useState(false);



  const updateStateFromData = (newData) => {
    const initialNamesMap = {};
    for (let group in newData.names_map) {
      initialNamesMap[group] = [...newData.names_map[group]];
    }
    setNamesMap(initialNamesMap);

    const assignedCompanies = new Set();
    for (let group in newData.names_map) {
      newData.names_map[group].forEach((company) =>
        assignedCompanies.add(company)
      );
    }
    setUnassignedCompanies(
      newData.all_names
        .filter((company) => !assignedCompanies.has(company))
        .sort((a, b) => a.localeCompare(b)) // Sort alphabetically
    );


    setContraData(newData.contra);
    setAllNames(newData.all_names);
  };

  useEffect(() => {
    updateStateFromData(data);
  }, [data]);

  const handleCompanyUnassign = (company, group) => {
    setNamesMap((prevNamesMap) => {
      const newNamesMap = { ...prevNamesMap };
      newNamesMap[group] = newNamesMap[group].filter((c) => c !== company);
      return newNamesMap;
    });
    setUnassignedCompanies((prev) =>
      [...prev, company].sort((a, b) => a.localeCompare(b))
    );
  };

  const handleCompanyAssign = (company, newGroup) => {
    setNamesMap((prevNamesMap) => {
      const newNamesMap = { ...prevNamesMap };
      if (!newNamesMap[newGroup]) {
        newNamesMap[newGroup] = [];
      }
      newNamesMap[newGroup] = [...newNamesMap[newGroup], company];
      return newNamesMap;
    });
    setUnassignedCompanies((prev) =>
      prev.filter((c) => c !== company).sort((a, b) => a.localeCompare(b))
    );
  };

  const handleAddNewGroup = () => {
    const trimmedName = newGroupName.trim();
    if (trimmedName !== "" && !namesMap.hasOwnProperty(trimmedName)) {
      setNamesMap((prevNamesMap) => ({
        ...prevNamesMap,
        [trimmedName]: [],
      }));
      setNewGroupName("");
      setShowNewGroupInput(false);
    }
  };

  const handleUpdateGroupings = async () => {
    setIsUpdating(true);
    try {
      const response = await fetch(getApiUrl("/api/set_exam_extra"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          exam_id: examID,
          extra_name: "ContraCompanyMap",
          extra_data: namesMap,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update groupings");
      }

      const result = await response.json();
      console.log("Groupings updated successfully:", result);

      if (result.results && result.results.data) {
        console.log("NEWCONTRANEWCONTRANEWCONTRA", result.results.data);
        updateStateFromData(result.results.data);
      }
    } catch (error) {
      console.error("Error updating groupings:", error);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleResetGroupings = async () => {
    setIsResetting(true);
    try {
      const response = await fetch(getApiUrl("/api/set_exam_extra"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          exam_id: examID,
          extra_name: "ContraCompanyMap",
          extra_data: null,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to reset groupings");
      }

      const result = await response.json();
      console.log("Groupings reset successfully:", result);

      if (result.results && result.results.data) {
        console.log("NEWCONTRANEWCONTRANEWCONTRA", result.results.data);
        updateStateFromData(result.results.data);
      }
    } catch (error) {
      console.error("Error resetting groupings:", error);
    } finally {
      setIsResetting(false);
    }
  };

  const groupNames = Object.keys(namesMap);


  return (
    <div className="flex">
      {/* Left side: Hierarchical grouping display */}
      <div className="w-1/3 p-4">
        <button
          onClick={handleUpdateGroupings}
          className={`bg-blue-500 text-white px-4 py-2 rounded mr-2 ${
            isUpdating || isResetting ? "cursor-not-allowed opacity-50" : ""
          }`}
          disabled={isUpdating || isResetting}
        >
          {isUpdating ? "Processing..." : "Update Groupings"}
        </button>
        <button
          onClick={handleResetGroupings}
          className={`bg-red-500 text-white px-4 py-2 rounded ${
            isUpdating || isResetting ? "cursor-not-allowed opacity-50" : ""
          }`}
          disabled={isUpdating || isResetting}
        >
          {isResetting ? "Processing..." : "Reset Groupings"}
        </button>
        <h2 className="text-xl font-bold mb-4 mt-4">Company Groupings</h2>
        {groupNames.map((group) => (
          <div key={group} className="mb-6">
            <h3 className="font-semibold">{group}</h3>
            <ul className="ml-4">
              {namesMap[group].map((company) => (
                <li key={company} className="flex items-center my-2">
                  <span>{company}</span>
                  <button
                    onClick={() => handleCompanyUnassign(company, group)}
                    className="text-red-500 ml-2"
                    title="Unassign from group"
                  >
                    x
                  </button>
                </li>
              ))}
            </ul>
          </div>
        ))}

        {/* Add New Group button and input */}
        <button
          onClick={() => setShowNewGroupInput(true)}
          className="bg-green-500 text-white px-4 py-2 rounded mb-4"
        >
          Add New Group
        </button>
        {showNewGroupInput && (
          <div className="mb-4">
            <input
              type="text"
              value={newGroupName}
              onChange={(e) => setNewGroupName(e.target.value)}
              placeholder="Enter new group name"
              className="border rounded p-2 mr-2"
            />
            <button
              onClick={handleAddNewGroup}
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              Add Group
            </button>
          </div>
        )}

        {/* Display unassigned companies, if any */}
        {unassignedCompanies.length > 0 && (
          <div className="mb-6">
            <h3 className="font-semibold">Unassigned</h3>
            <ul className="ml-4">
              {unassignedCompanies.map((company) => (
                <li key={company} className="flex items-center my-2">
                  <span>{company}</span>
                  <select
                    value=""
                    onChange={(e) =>
                      handleCompanyAssign(company, e.target.value)
                    }
                    className="border rounded p-1 ml-2"
                  >
                    <option value="" disabled>
                      Assign to group
                    </option>
                    {groupNames.map((g) => (
                      <option key={g} value={g}>
                        {g}
                      </option>
                    ))}
                  </select>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {/* Right side: Contra table display */}
      <div className="w-2/3 p-4">
        <ArrayTable data={contraData} />
      </div>
    </div>
  );
};

export default ContraAnalysisDisplay; 
