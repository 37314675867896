// import React, { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import getApiUrl from "./config";

// const buttonBaseClass =
//   "inline-block w-full rounded px-8 py-3 text-sm font-medium transition hover:scale-110 hover:shadow-xl focus:outline-none focus:ring";
// const buttonVariantClass = {
//   FEOrange: `bg-FEOrange text-white active:bg-FEOrangeActive`,
//   FEBlue: `bg-FEBlue text-white active:bg-blue-700`,
// };

// const Button = ({ text, onClick, variant }) => {
//   return (
//     <button
//       className={`${buttonBaseClass} ${buttonVariantClass[variant]}`}
//       onClick={onClick}
//     >
//       {text}
//     </button>
//   );
// };

// const LoginPage = () => {
//   const navigate = useNavigate();

//   useEffect(() => {
//     const checkAuthStatus = async () => {
//       console.log("Checking authentication status");
//       try {
//         const response = await fetch(getApiUrl("/api/check_auth"));
//         const data = await response.json();
//         if (response.ok && data.authenticated) {
//           console.log("User is authenticated, redirecting to dashboard");
//           navigate("/dashboard");
//         }
//       } catch (error) {
//         console.error("Error checking authentication status:", error);
//       }
//     };
//     checkAuthStatus();
//   }, [navigate]);

//   const handleGoogleLogin = async () => {
//     console.log("Initiating Google login process");
//     try {
//       console.log("LOGIN URL:", getApiUrl("/api/login-url"));
//       const response = await fetch(getApiUrl("/api/login-url"));
//       const data = await response.json();
//       if (response.ok && data.login_url) {
//         console.log("Redirecting to Google login URL:", data.login_url);
//         window.location.href = data.login_url;
//       } else {
//         console.error("Failed to get login URL");
//       }
//     } catch (error) {
//       console.error("Error during login process:", error);
//     }
//   };

//   const handleMicrosoftLogin = async () => {
//     console.log("Initiating Microsoft login process");
//     try {
//       const response = await fetch(getApiUrl("/api/login-url/microsoft"));
//       const data = await response.json();
//       if (response.ok && data.login_url) {
//         console.log("Redirecting to Microsoft login URL:", data.login_url);
//         window.location.href = data.login_url;
//       } else {
//         console.error("Failed to get Microsoft login URL");
//       }
//     } catch (error) {
//       console.error("Error during login process:", error);
//     }
//   };

//   return (
//     <div className="flex items-center justify-center min-h-screen bg-gray-100">
//       <div className="p-6 bg-white rounded shadow-md">
//         <h1 className="mb-4 text-2xl font-bold text-center">
//           Welcome to ExamFlow
//         </h1>
//         <div className="flex flex-col gap-4">
//           <Button
//             text="Log in with Gmail"
//             onClick={handleGoogleLogin}
//             variant="FEBlue"
//           />
//           <Button
//             text="Log in with Microsoft"
//             onClick={handleMicrosoftLogin}
//             variant="FEOrange"
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default LoginPage;
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import getApiUrl from "./config";

const buttonBaseClass =
  "inline-block w-full rounded px-8 py-3 text-sm font-medium transition hover:scale-110 hover:shadow-xl focus:outline-none focus:ring";
const buttonVariantClass = {
  FEOrange: `bg-FEOrange text-white active:bg-FEOrangeActive`,
  FEBlue: `bg-FEBlue text-white active:bg-blue-700`,
  FEGreen: `bg-green-500 text-white active:bg-green-700`,
};

const Button = ({ text, onClick, variant }) => {
  return (
    <button
      className={`${buttonBaseClass} ${buttonVariantClass[variant]}`}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

const LoginPage = () => {
  const navigate = useNavigate();
  const [randomNumber, setRandomNumber] = useState(null);

  useEffect(() => {
    const checkAuthStatus = async () => {
      console.log("Checking authentication status");
      try {
        const response = await fetch(getApiUrl("/api/check_auth"));
        const data = await response.json();
        if (response.ok && data.authenticated) {
          console.log("User is authenticated, redirecting to dashboard");
          navigate("/dashboard");
        }
      } catch (error) {
        console.error("Error checking authentication status:", error);
      }
    };
    checkAuthStatus();
  }, [navigate]);

  const handleGoogleLogin = async () => {
    console.log("Initiating Google login process");
    try {
      console.log("LOGIN URL:", getApiUrl("/api/login-url"));
      const response = await fetch(getApiUrl("/api/login-url"));
      const data = await response.json();
      console.log("DATA:", data);
      if (response.ok && data.login_url) {
        console.log("Redirecting to Google login URL:", data.login_url);
        window.location.href = data.login_url;
      } else {
        console.error("Failed to get login URL");
      }
    } catch (error) {
      console.error("Error during login process:", error);
    }
  };

  const handleMicrosoftLogin = async () => {
    console.log("Initiating Microsoft login process");
    try {
      const response = await fetch(getApiUrl("/api/login-url/microsoft"));
      const data = await response.json();
      if (response.ok && data.login_url) {
        console.log("Redirecting to Microsoft login URL:", data.login_url);
        window.location.href = data.login_url;
      } else {
        console.error("Failed to get Microsoft login URL");
      }
    } catch (error) {
      console.error("Error during login process:", error);
    }
  };



  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="p-6 bg-white rounded shadow-md">
        <h1 className="mb-4 text-2xl font-bold text-center">
          Welcome to ExamFlow
        </h1>
        <div className="flex flex-col gap-4">
          <Button
            text="Log in with Gmail"
            onClick={handleGoogleLogin}
            variant="FEBlue"
          />
          <Button
            text="Log in with Microsoft"
            onClick={handleMicrosoftLogin}
            variant="FEOrange"
          />

        </div>
      </div>
    </div>
  );
};

export default LoginPage;