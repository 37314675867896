import React, { useMemo, useEffect, useRef, useState } from "react";
const CustomTable = React.memo(function CustomTable({
  rows,
  orderedKeys = [],
  excludedKeys = [],
  headerMappings = {},
  mergeEnabled = false,
  showRowNumbers = false,
  formatNumbers = false,
  callout = [],
}) {
  const formatNumber = (value) => {
    const num = parseFloat(value);
    if (isNaN(num)) return value;
    if (Number.isInteger(num)) return num.toLocaleString("en-US");
    return num.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  const tableRef = useRef(null);
  const [hasScrolled, setHasScrolled] = useState(false);

  const formatCellValue = (item, value) => {
    if (value == null) return ""; // Handle null or undefined values
    if (typeof value !== "string" && typeof value !== "number")
      return value.toString();

    let formattedValue = value.toString();
    if (
      (item && (item.startsWith("#") || item.startsWith("$"))) ||
      formatNumbers
    ) {
      formattedValue = formatNumber(formattedValue);
      if (item && item.startsWith("$")) {
        formattedValue = `$${formattedValue}`;
      }
    }

    return formattedValue;
  };

  const processedRows = useMemo(() => {
    return (rows || []).map((row) => {
      const newRow = { ...row };
      if (typeof newRow.Item === "string") {
        newRow.OriginalItem = newRow.Item;
        newRow.Item = newRow.Item.replace(/^[#$]/, "");
      }
      if (newRow.Value !== undefined) {
        newRow.FormattedValue = formatCellValue(
          newRow.OriginalItem || newRow.Item,
          newRow.Value
        );
      }
      return newRow;
    });
  }, [rows, formatNumbers]);

  const mergedRows = useMemo(() => {
    if (!mergeEnabled) return processedRows;

    return processedRows.map((row, index, array) => {
      if (index % 2 === 0 && index + 1 < array.length) {
        let newRow = { ...row };
        ["Unique Letter", "Match Value"].forEach((key) => {
          if (row[key] === array[index + 1][key]) {
            newRow[key] = {
              value: row[key],
              rowspan: 2,
            };
            array[index + 1][key] = {
              value: row[key],
              rowspan: 0,
            };
          }
        });
        return newRow;
      }
      return row;
    });
  }, [processedRows, mergeEnabled]);

  const { headers } = useMemo(() => {
    const allKeys = Array.from(new Set(rows.flatMap(Object.keys)));

    // const allKeys = processedRows.reduce((keys, row) => {
    //   Object.keys(row).forEach((key) => {
    //     if (!keys.includes(key)) keys.push(key);
    //   });
    //   return keys;
    // }, []);

    const filteredKeys = allKeys.filter(
      (key) =>
        (orderedKeys.includes(key) || !excludedKeys.includes(key)) &&
        key !== "OriginalItem" &&
        key !== "FormattedValue"
    );

    const orderedKeysSet = new Set(orderedKeys);
    const headers = [
      ...orderedKeys.filter((key) => filteredKeys.includes(key)),
      ...filteredKeys.filter((key) => !orderedKeysSet.has(key)),
    ];

    const matchIndex = headers.findIndex((key) =>
      key.toLowerCase().includes("match")
    );
    if (matchIndex !== -1) {
      const matchKey = headers.splice(matchIndex, 1)[0];
      headers.push(matchKey);
    }

    return { headers };
  }, [processedRows, orderedKeys, excludedKeys]);

  const calloutMap = useMemo(() => {
    const map = new Map();
    callout.forEach(([value, cellsInfo, _, matchStatus]) => {
      cellsInfo.forEach(({ cells }) => {
        cells.forEach((cell) => {
          if (Array.isArray(cell[0])) {
            cell.forEach(([row, column]) => {
              map.set(`${row},${column}`, matchStatus);
            });
          } else {
            const [row, column] = cell;
            map.set(`${row},${column}`, matchStatus);
          }
        });
      });
    });
    return map;
  }, [callout]);

  const rowHighlightCounts = useMemo(() => {
    const counts = new Map();
    mergedRows.forEach((_, rowIndex) => {
      let highlightCount = 0;
      headers.forEach((header) => {
        const matchStatus = calloutMap.get(`${rowIndex},${header}`);
        if (matchStatus !== undefined) {
          highlightCount += 1;
        }
      });
      counts.set(rowIndex, highlightCount);
    });
    return counts;
  }, [mergedRows, headers, calloutMap]);

  const maxHighlightCount = Math.max(...rowHighlightCounts.values());
  const rowsWithMostHighlights = new Set(
    Array.from(rowHighlightCounts.entries())
      .filter(([, count]) => count === maxHighlightCount)
      .map(([rowIndex]) => rowIndex)
  );

  const getHighlightClass = (rowIndex, header) => {
    if (!rowsWithMostHighlights.has(rowIndex)) return "";

    const matchStatus = calloutMap.get(`${rowIndex},${header}`);
    if (matchStatus === undefined) return "";

    switch (matchStatus === null ? "null" : matchStatus.toLowerCase()) {
      case "match":
      case "exact match":
        return "bg-green-100";
      case "mismatch":
        return "bg-red-100";
      case "missing":
        return "bg-yellow-100";
      case "ambiguous":
        return "bg-orange-100";
      case "null":
        return "bg-blue-100";
      default:
        return "";
    }
  };

  useEffect(() => {
    if (!hasScrolled && tableRef.current && rowsWithMostHighlights.size > 0) {
      const firstHighlightedRow = tableRef.current.querySelector(
        `tr:nth-child(${Array.from(rowsWithMostHighlights)[0] + 2})`
      );
      if (firstHighlightedRow) {
        const scrollContainer = tableRef.current;
        const rowPosition = firstHighlightedRow.offsetTop;
        const offset = scrollContainer.clientHeight / 2;
        scrollContainer.scrollTo({
          top: rowPosition - offset,
          behavior: "smooth",
        });
        setHasScrolled(true); // Ensure scrolling happens only once
      }
    }
  }, [hasScrolled, rowsWithMostHighlights]);


  const getMatchValueBackgroundColor = (value, isLastRow) => {
    if (!value) return ""; // Handle null or undefined values
    if (isLastRow) {
      switch (value.toString().toLowerCase()) {
        case "match":
        case "exact match":
          return "bg-green-400";
        case "mismatch":
          return "bg-red-400";
        case "missing":
          return "bg-yellow-400";
        case "ambiguous":
          return "bg-orange-400";
        default:
          return "";
      }
    } else {
      switch (value.toString().toLowerCase()) {
        case "match":
        case "exact match":
          return "bg-green-100";
        case "mismatch":
          return "bg-red-100";
        case "missing":
          return "bg-yellow-100";
        case "ambiguous":
          return "bg-orange-100";
        default:
          return "";
      }
    }
  };

  const formatItemContent = (content) => {
    if (!content) return null; // Handle null or undefined content
    if (!content.includes(":"))
      return <span className="font-bold">{content}</span>;

    const [left, ...right] = content.split(":");
    const formattedLeft =
      left.charAt(0).toUpperCase() + left.slice(1).toUpperCase();
    const formattedRight = right
      .join(":")
      .trim()
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");

    return (
      <>
        <span className="font-bold">{formattedLeft}</span>
        {right.length > 0 && (
          <>
            <br />
            <span>{formattedRight}</span>
          </>
        )}
      </>
    );
  };

  if (!processedRows.length) return null;

  return (
    <div
      className="w-[630px] overflow-x-auto max-h-[1000px] overflow-y-auto border border-gray-300 rounded-lg"
      ref={tableRef}
    >
      <div className="rounded-lg border border-gray-300 shadow-sm overflow-hidden">
        <table className="table-auto w-full divide-y-2 divide-gray-300 bg-white text-sm rounded-lg">
          <thead className="bg-gray-100">
            <tr className="divide-x divide-gray-300">
              {showRowNumbers && (
                <th className="px-2 py-2 text-gray-900 text-center sticky left-0 bg-gray-100 w-min">
                  #
                </th>
              )}
              {headers.map((header, index) => (
                <th
                  key={index}
                  className={`px-2 py-2 font-medium text-gray-900 text-left whitespace-normal break-words ${
                    header.toLowerCase().includes("match")
                      ? "w-min text-center"
                      : ""
                  }`}
                >
                  {headerMappings[header] || header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-300">
            {mergedRows.map((row, rowIndex) => (
              <tr key={rowIndex} className="divide-x divide-gray-300">
                {showRowNumbers && (
                  <td className="px-2 py-1 text-gray-700 font-bold text-center sticky left-0 bg-white w-min font-bold">
                    {rowIndex + 1}
                  </td>
                )}
                {headers.map((header, colIndex) => {
                  const cell = row[header];
                  let cellValue =
                    typeof cell === "object" && cell !== null
                      ? cell.value
                      : cell;
                  let displayValue =
                    typeof cellValue === "boolean"
                      ? cellValue.toString()
                      : cellValue;

                  const isLastRow = rowIndex === mergedRows.length - 1;
                  const cellBgColor = header.toLowerCase().includes("match")
                    ? getMatchValueBackgroundColor(cellValue, isLastRow)
                    : "";

                  if (header === "Item" && typeof displayValue === "string") {
                    displayValue = formatItemContent(displayValue);
                  } else if (
                    header === "Value" &&
                    row.FormattedValue !== undefined
                  ) {
                    displayValue = row.FormattedValue;
                  } else if (
                    header.toLowerCase().includes("match") &&
                    typeof displayValue === "string"
                  ) {
                    displayValue =
                      displayValue.charAt(0).toUpperCase() +
                      displayValue.slice(1).toLowerCase();
                  }

                  const isLastColumn = colIndex === headers.length - 1;
                  const highlightClass = getHighlightClass(rowIndex, header);
                  const cellClass = `px-2 py-2 text-gray-700 text-left ${cellBgColor} ${highlightClass} whitespace-normal break-words ${
                    !isLastColumn ? "border-r border-gray-300" : ""
                  }`;
                  const numericClass =
                    header === "Value" &&
                    (row.OriginalItem?.startsWith("#") ||
                      row.OriginalItem?.startsWith("$"))
                      ? "text-left"
                      : "";
                  const matchClass = header.toLowerCase().includes("match")
                    ? "text-center w-min"
                    : "";

                  if (
                    typeof cell === "object" &&
                    cell !== null &&
                    "rowspan" in cell
                  ) {
                    return cell.rowspan > 0 ? (
                      <td
                        key={header}
                        className={`${cellClass} ${numericClass} ${matchClass}`}
                        rowSpan={cell.rowspan}
                      >
                        {displayValue}
                      </td>
                    ) : null;
                  }
                  return (
                    <td
                      key={header}
                      className={`${cellClass} ${numericClass} ${matchClass}`}
                    >
                      {displayValue}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
});

export default CustomTable;
