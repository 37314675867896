// // // import React, { useState, useEffect } from "react";

// // // const FileUploadAndDisplay = () => {
// // //   const [file, setFile] = useState(null);
// // //   const [isUploading, setIsUploading] = useState(false);
// // //   const [message, setMessage] = useState("");
// // //   const [tableData, setTableData] = useState(null);

// // //   const handleFileChange = (e) => {
// // //     setFile(e.target.files[0]);
// // //   };

// // //   const handleFileUpload = async (e) => {
// // //     e.preventDefault();
// // //     if (!file) {
// // //       setMessage("Please select a file first.");
// // //       return;
// // //     }
// // //     setIsUploading(true);
// // //     setMessage("");

// // //     const formData = new FormData();
// // //     formData.append("file", file);

// // //     try {
// // //       const response = await fetch("/api/table_upload", {
// // //         method: "POST",
// // //         body: formData,
// // //       });
// // //       const data = await response.json();
// // //       if (data.status === "success") {
// // //         setMessage("File uploaded successfully");
// // //         fetchTableData();
// // //       } else {
// // //         setMessage("File upload failed");
// // //       }
// // //     } catch (error) {
// // //       console.error("Error uploading file:", error);
// // //       setMessage("An error occurred while uploading the file.");
// // //     } finally {
// // //       setIsUploading(false);
// // //     }
// // //   };

// // //   const fetchTableData = async () => {
// // //     try {
// // //       const response = await fetch("/api/get_table", {
// // //         method: "POST",
// // //         headers: {
// // //           "Content-Type": "application/json",
// // //         },
// // //       });
// // //       const data = await response.json();
// // //       if (data.status === "success") {
// // //         setTableData(data.table_data);
// // //       } else {
// // //         setMessage("Failed to fetch table data");
// // //       }
// // //     } catch (error) {
// // //       console.error("Error fetching table data:", error);
// // //       setMessage("An error occurred while fetching table data.");
// // //     }
// // //   };

// // //   return (
// // //     <div className="min-h-screen flex flex-col">
// // //       <div className="flex-grow bg-white">
// // //         <div className="p-4 sm:p-6 md:p-8 h-full">
// // //           <div className="bg-gray-50 p-6 rounded-lg border border-gray-200">
// // //             <p className="text-lg font-medium text-gray-700 mb-4">
// // //               Upload file
// // //             </p>
// // //             <form onSubmit={handleFileUpload} className="space-y-4">
// // //               <div>
// // //                 <input
// // //                   id="file-upload"
// // //                   type="file"
// // //                   onChange={handleFileChange}
// // //                   accept=".pdf,.xlsx,.csv"
// // //                   className="mt-1 block w-full text-sm text-gray-500
// // //                   file:mr-4 file:py-2 file:px-4
// // //                   file:rounded-full file:border-0
// // //                   file:text-sm file:font-semibold
// // //                   file:bg-blue-50 file:text-blue-700
// // //                   hover:file:bg-blue-100"
// // //                 />
// // //               </div>
// // //               <div className="flex justify-start">
// // //                 <button
// // //                   type="submit"
// // //                   disabled={isUploading || !file}
// // //                   className="inline-flex justify-center py-2 px-8 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-gray-400 disabled:cursor-not-allowed"
// // //                 >
// // //                   {isUploading ? "Uploading..." : "Upload"}
// // //                 </button>
// // //               </div>
// // //             </form>
// // //             {message && <p className="mt-4 text-sm text-gray-600">{message}</p>}
// // //           </div>

// // //           {tableData && (
// // //             <div className="mt-8 bg-white rounded-lg border border-gray-200 overflow-hidden">
// // //               <div className="overflow-x-auto">
// // //                 <table className="min-w-full divide-y divide-gray-200">
// // //                   <thead className="bg-gray-50">
// // //                     <tr>
// // //                       {Object.keys(tableData[0]).map((header) => (
// // //                         <th
// // //                           key={header}
// // //                           scope="col"
// // //                           className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
// // //                         >
// // //                           {header}
// // //                         </th>
// // //                       ))}
// // //                     </tr>
// // //                   </thead>
// // //                   <tbody className="bg-white divide-y divide-gray-200">
// // //                     {tableData.map((row, rowIndex) => (
// // //                       <tr key={rowIndex}>
// // //                         {Object.values(row).map((cell, cellIndex) => (
// // //                           <td
// // //                             key={cellIndex}
// // //                             className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
// // //                           >
// // //                             {cell}
// // //                           </td>
// // //                         ))}
// // //                       </tr>
// // //                     ))}
// // //                   </tbody>
// // //                 </table>
// // //               </div>
// // //             </div>
// // //           )}
// // //         </div>
// // //       </div>
// // //     </div>
// // //   );
// // // };

// // // export default FileUploadAndDisplay;
// // import React, { useState, useEffect } from "react";

// // const TableExtractPage = () => {
// //   const [file, setFile] = useState(null);
// //   const [isUploading, setIsUploading] = useState(false);
// //   const [message, setMessage] = useState("");
// //   const [tableData, setTableData] = useState(null);

// //   const handleFileChange = (e) => {
// //     setFile(e.target.files[0]);
// //   };

// //   const handleFileUpload = async (e) => {
// //     e.preventDefault();
// //     if (!file) {
// //       setMessage("Please select a file first.");
// //       return;
// //     }
// //     setIsUploading(true);
// //     setMessage("");

// //     const formData = new FormData();
// //     formData.append("file", file);

// //     try {
// //       const response = await fetch("/api/table_upload", {
// //         method: "POST",
// //         body: formData,
// //       });
// //       const data = await response.json();
// //       if (data.status === "success") {
// //         setMessage("File uploaded successfully");
// //         fetchTableData();
// //       } else {
// //         setMessage("File upload failed");
// //       }
// //     } catch (error) {
// //       console.error("Error uploading file:", error);
// //       setMessage("An error occurred while uploading the file.");
// //     } finally {
// //       setIsUploading(false);
// //     }
// //   };

// //   const fetchTableData = async () => {
// //     try {
// //       const response = await fetch("/api/get_table", {
// //         method: "POST",
// //         headers: {
// //           "Content-Type": "application/json",
// //         },
// //       });
// //       const data = await response.json();
// //       if (data.status === "success") {
// //         setTableData(data.table_data);
// //       } else {
// //         setMessage("Failed to fetch table data");
// //       }
// //     } catch (error) {
// //       console.error("Error fetching table data:", error);
// //       setMessage("An error occurred while fetching table data.");
// //     }
// //   };

// //   const handleDownload = () => {
// //     if (!tableData) return;

// //     const headers = Object.keys(tableData[0]);
// //     const csvContent = [
// //       headers.join(","),
// //       ...tableData.map((row) => headers.map((header) => row[header]).join(",")),
// //     ].join("\n");

// //     const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
// //     const link = document.createElement("a");
// //     if (link.download !== undefined) {
// //       const url = URL.createObjectURL(blob);
// //       link.setAttribute("href", url);
// //       link.setAttribute("download", "table_data.csv");
// //       link.style.visibility = "hidden";
// //       document.body.appendChild(link);
// //       link.click();
// //       document.body.removeChild(link);
// //     }
// //   };

// //   return (
// //     <div className="min-h-screen flex flex-col">
// //       <div className="flex-grow bg-white">
// //         <div className="p-4 sm:p-6 md:p-8 h-full">
// //           <div className="bg-gray-50 p-6 rounded-lg border border-gray-200">
// //             <p className="text-lg font-medium text-gray-700 mb-4">
// //               Upload file
// //             </p>
// //             <form onSubmit={handleFileUpload} className="space-y-4">
// //               <div>
// //                 <input
// //                   id="file-upload"
// //                   type="file"
// //                   onChange={handleFileChange}
// //                   accept=".pdf,.xlsx,.csv"
// //                   className="mt-1 block w-full text-sm text-gray-500
// //                   file:mr-4 file:py-2 file:px-4
// //                   file:rounded-full file:border-0
// //                   file:text-sm file:font-semibold
// //                   file:bg-blue-50 file:text-blue-700
// //                   hover:file:bg-blue-100"
// //                 />
// //               </div>
// //               <div className="flex justify-start">
// //                 <button
// //                   type="submit"
// //                   disabled={isUploading || !file}
// //                   className="inline-flex justify-center py-2 px-8 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-gray-400 disabled:cursor-not-allowed"
// //                 >
// //                   {isUploading ? "Uploading..." : "Upload"}
// //                 </button>
// //               </div>
// //             </form>
// //             {message && <p className="mt-4 text-sm text-gray-600">{message}</p>}
// //           </div>

// //           {tableData && (
// //             <div className="mt-8">
// //               <div className="flex justify-end mb-4">
// //                 <button
// //                   onClick={handleDownload}
// //                   className="inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
// //                 >
// //                   Download CSV
// //                 </button>
// //               </div>
// //               <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
// //                 <div className="overflow-x-auto">
// //                   <table className="min-w-full divide-y divide-gray-200">
// //                     <thead className="bg-gray-50">
// //                       <tr>
// //                         {Object.keys(tableData[0]).map((header) => (
// //                           <th
// //                             key={header}
// //                             scope="col"
// //                             className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
// //                           >
// //                             {header}
// //                           </th>
// //                         ))}
// //                       </tr>
// //                     </thead>
// //                     <tbody className="bg-white divide-y divide-gray-200">
// //                       {tableData.map((row, rowIndex) => (
// //                         <tr key={rowIndex}>
// //                           {Object.values(row).map((cell, cellIndex) => (
// //                             <td
// //                               key={cellIndex}
// //                               className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
// //                             >
// //                               {cell}
// //                             </td>
// //                           ))}
// //                         </tr>
// //                       ))}
// //                     </tbody>
// //                   </table>
// //                 </div>
// //               </div>
// //             </div>
// //           )}
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default TableExtractPage;

// import React, { useState, useEffect } from "react";
// import Header from "./components/Header";

// const TableExtractPage = () => {
//   const [file, setFile] = useState(null);
//   const [isUploading, setIsUploading] = useState(false);
//   const [message, setMessage] = useState("");
//   const [tableData, setTableData] = useState(null);

//   const handleFileChange = (e) => {
//     setFile(e.target.files[0]);
//   };

//   const handleFileUpload = async (e) => {
//     e.preventDefault();
//     if (!file) {
//       setMessage("Please select a file first.");
//       return;
//     }
//     setIsUploading(true);
//     setMessage("");

//     const formData = new FormData();
//     formData.append("file", file);

//     try {
//       const response = await fetch("/api/table_upload", {
//         method: "POST",
//         body: formData,
//       });
//       const data = await response.json();
//       if (data.status === "success") {
//         setMessage("File uploaded successfully");
//         fetchTableData();
//       } else {
//         setMessage("File upload failed");
//       }
//     } catch (error) {
//       console.error("Error uploading file:", error);
//       setMessage("An error occurred while uploading the file.");
//     } finally {
//       setIsUploading(false);
//     }
//   };

//   const fetchTableData = async () => {
//     try {
//       const response = await fetch("/api/get_table", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//       });
//       const data = await response.json();
//       if (data.status === "success") {
//         setTableData(data.table_data);
//       } else {
//         setMessage("Failed to fetch table data");
//       }
//     } catch (error) {
//       console.error("Error fetching table data:", error);
//       setMessage("An error occurred while fetching table data.");
//     }
//   };

//   const handleDownload = () => {
//     if (!tableData) return;

//     const headers = Object.keys(tableData[0]);
//     const csvContent = [
//       headers.join(","),
//       ...tableData.map((row) => headers.map((header) => row[header]).join(",")),
//     ].join("\n");

//     const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
//     const link = document.createElement("a");
//     if (link.download !== undefined) {
//       const url = URL.createObjectURL(blob);
//       link.setAttribute("href", url);
//       link.setAttribute("download", "table_data.csv");
//       link.style.visibility = "hidden";
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
//     }
//   };

//   return (
//     <div className="min-h-screen flex flex-col">
//       <Header buttons={[]} />
//       <div className="flex-grow bg-white mt-16">
//         <div className="p-4 sm:p-6 md:p-8 h-full">
//           <div className="flex flex-col lg:flex-row lg:space-x-8 h-full">
//             <div className="lg:w-1/2 space-y-6">
//               <div className="bg-gray-50 p-6 rounded-lg border border-gray-200">
//                 <p className="text-lg font-medium text-gray-700 mb-4">
//                   Upload file
//                 </p>
//                 <form onSubmit={handleFileUpload} className="space-y-4">
//                   <div>
//                     <input
//                       id="file-upload"
//                       type="file"
//                       onChange={handleFileChange}
//                       accept=".pdf,.xlsx,.csv"
//                       className="mt-1 block w-full text-sm text-gray-500
//                       file:mr-4 file:py-2 file:px-4
//                       file:rounded-full file:border-0
//                       file:text-sm file:font-semibold
//                       file:bg-blue-50 file:text-blue-700
//                       hover:file:bg-blue-100"
//                     />
//                   </div>
//                   <div className="flex justify-start">
//                     <button
//                       type="submit"
//                       disabled={isUploading || !file}
//                       className="inline-flex justify-center py-2 px-8 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-gray-400 disabled:cursor-not-allowed"
//                     >
//                       {isUploading ? "Uploading..." : "Upload"}
//                     </button>
//                   </div>
//                 </form>
//                 {message && (
//                   <p className="mt-4 text-sm text-gray-600">{message}</p>
//                 )}
//               </div>

//               {tableData && (
//                 <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
//                   <div className="p-4 border-b border-gray-200">
//                     <button
//                       onClick={handleDownload}
//                       className="inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
//                     >
//                       Download CSV
//                     </button>
//                   </div>
//                   <div className="overflow-x-auto">
//                     <table className="min-w-full divide-y divide-gray-200">
//                       <thead className="bg-gray-50">
//                         <tr>
//                           {Object.keys(tableData[0]).map((header) => (
//                             <th
//                               key={header}
//                               scope="col"
//                               className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
//                             >
//                               {header}
//                             </th>
//                           ))}
//                         </tr>
//                       </thead>
//                       <tbody className="bg-white divide-y divide-gray-200">
//                         {tableData.map((row, rowIndex) => (
//                           <tr key={rowIndex}>
//                             {Object.values(row).map((cell, cellIndex) => (
//                               <td
//                                 key={cellIndex}
//                                 className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
//                               >
//                                 {cell}
//                               </td>
//                             ))}
//                           </tr>
//                         ))}
//                       </tbody>
//                     </table>
//                   </div>
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default TableExtractPage;

import React, { useState, useEffect } from "react";
import Header from "./components/Header";

const TableExtractPage = () => {
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [message, setMessage] = useState("");
  const [tableData, setTableData] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();
    if (!file) {
      setMessage("Please select a file first.");
      return;
    }
    setIsUploading(true);
    setMessage("");

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch("/api/table_upload", {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      if (data.status === "success") {
        setMessage("File uploaded successfully");
        fetchTableData();
      } else {
        setMessage("File upload failed");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setMessage("An error occurred while uploading the file.");
    } finally {
      setIsUploading(false);
    }
  };

  const fetchTableData = async () => {
    try {
      const response = await fetch("/api/get_table", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.status === "success") {
        setTableData(data.table_data);
      } else {
        setMessage("Failed to fetch table data");
      }
    } catch (error) {
      console.error("Error fetching table data:", error);
      setMessage("An error occurred while fetching table data.");
    }
  };

  const handleDownload = () => {
    if (!tableData) return;

    const headers = Object.keys(tableData[0]);
    const csvContent = [
      headers.join(","),
      ...tableData.map((row) => headers.map((header) => row[header]).join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "table_data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Header buttons={[]} />
      <div className="flex-grow bg-white mt-16">
        <div className="p-4 sm:p-6 md:p-8 h-full">
          <div className="space-y-6">
            <div className="bg-gray-50 p-6 rounded-lg border border-gray-200">
              <p className="text-lg font-medium text-gray-700 mb-4">
                Upload file
              </p>
              <form onSubmit={handleFileUpload} className="space-y-4">
                <div>
                  <input
                    id="file-upload"
                    type="file"
                    onChange={handleFileChange}
                    accept=".pdf,.xlsx,.csv"
                    className="mt-1 block w-full text-sm text-gray-500
                    file:mr-4 file:py-2 file:px-4
                    file:rounded-full file:border-0
                    file:text-sm file:font-semibold
                    file:bg-blue-50 file:text-blue-700
                    hover:file:bg-blue-100"
                  />
                </div>
                <div className="flex justify-start">
                  <button
                    type="submit"
                    disabled={isUploading || !file}
                    className="inline-flex justify-center py-2 px-8 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-gray-400 disabled:cursor-not-allowed"
                  >
                    {isUploading ? "Uploading..." : "Upload"}
                  </button>
                </div>
              </form>
              {message && (
                <p className="mt-4 text-sm text-gray-600">{message}</p>
              )}
            </div>

            {tableData && (
              <>
                <div className="flex justify-end mb-4">
                  <button
                    onClick={handleDownload}
                    className="inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                  >
                    Download CSV
                  </button>
                </div>
                <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
                  <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          {Object.keys(tableData[0]).map((header) => (
                            <th
                              key={header}
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {tableData.map((row, rowIndex) => (
                          <tr key={rowIndex}>
                            {Object.values(row).map((cell, cellIndex) => (
                              <td
                                key={cellIndex}
                                className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                              >
                                {cell}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableExtractPage;