import React from "react";
import ImageWithScroll from "./ImageWithScroll";
import TableFromListOfDicts from "./SpreadsheetTable";

function ImageOrTable({ data, subKey, title, onImageClick }) {
  if (typeof data === "string") {
    return (
      <ImageWithScroll
        imgData={data}
        subKey={subKey}
        title={title}
        onImageClick={onImageClick}
      />
    );
  } else if (typeof data === "object" && data !== null) {
    return (
      <TableFromListOfDicts
        listOfDicts={data["table"]}
        callout={data["callout"]}
        title={title}
      />
    );
  } else {
    console.error(`Invalid data type for ${subKey}:`, data);
    return null;
  }
}

export default ImageOrTable;
