// // import React, { useState, useMemo } from "react";
// // import { ChevronUp, ChevronDown } from "lucide-react";

// // const UnmatchedDocs = ({ examData, currentTest, handleCellClick, samplesData }) => {
// //   const [isOpen, setIsOpen] = useState(true);
// //   const [hoveredDoc, setHoveredDoc] = useState(null);

// //   // Move useMemo to the top, before any conditional returns
// //   const sortedDocs = useMemo(() => {
// //     if (!examData || !currentTest || !examData[currentTest]) {
// //       return [];
// //     }
// //     const unmatched_docs = examData[currentTest].unmatched_docs;
// //     if (!unmatched_docs || unmatched_docs.length === 0) {
// //       return [];
// //     }
// //     return [...unmatched_docs].sort((a, b) => a.file_name.localeCompare(b.file_name));
// //   }, [examData, currentTest]);

// //   // Helper function to check if a document is in the samples
// //   const isDocInSamples = (doc) => {
// //     if (!samplesData) return false;
// //     return Object.values(samplesData).some(sampleDocs => 
// //       sampleDocs.some(sampleDoc => 
// //         sampleDoc.file_id === doc.file_id || sampleDoc.doc_hash === doc.doc_hash
// //       )
// //     );
// //   };

// //   const handleDragStart = (e, doc) => {
// //     e.dataTransfer.setData(
// //       "text/plain",
// //       JSON.stringify({
// //         currentTest,
// //         docType: doc.doc_type,
// //         fileName: doc.file_name,
// //         docHash: doc.doc_hash,
// //         isUnmatched: true,
// //         fileId: doc.file_id,
// //         image: doc.image,
// //       })
// //     );
// //   };

// //   const toggleOpen = () => {
// //     setIsOpen(!isOpen);
// //   };

// //   // If there are no documents to display, return null
// //   if (sortedDocs.length === 0) {
// //     return null;
// //   }

// //   return (
// //     <div className="m-8">
// //       <div className="flex items-center mb-4">
// //         <h2 className="text-xl font-semibold mr-2">All Documents</h2>
// //         <button onClick={toggleOpen} className="focus:outline-none">
// //           {isOpen ? (
// //             <ChevronUp className="w-6 h-6" />
// //           ) : (
// //             <ChevronDown className="w-6 h-6" />
// //           )}
// //         </button>
// //       </div>
// //       {isOpen && (
// //         <div className="flex flex-wrap -mx-2">
// //           {sortedDocs.map((doc, index) => {
// //             const isInSamples = isDocInSamples(doc);
// //             return (
// //               <div
// //                 key={index}
// //                 className="w-64 px-2 mb-4"
// //                 onClick={() => handleCellClick(doc.file_id)}
// //                 style={{ cursor: "pointer" }}
// //                 draggable
// //                 onDragStart={(e) => handleDragStart(e, doc)}
// //                 onMouseEnter={() => setHoveredDoc(doc.doc_hash)}
// //                 onMouseLeave={() => setHoveredDoc(null)}
// //               >
// //                 <div className={`border rounded-lg p-4 h-full flex flex-col ${isInSamples ? 'bg-white' : 'bg-gray-100'}`}>
// //                   <div className="font-medium mb-2">{doc.doc_type}</div>
// //                   <div className="text-sm mb-2 flex-grow">{doc.file_name}</div>
// //                   <div className="inline-grid grid-cols-1 grid-rows-1 justify-items-center w-full">
// //                     <img
// //                       src={`data:image/png;base64,${doc.image}`}
// //                       alt={doc.file_name}
// //                       className="w-48 h-auto object-cover mt-2 col-start-1 row-start-1"
// //                     />
// //                     {hoveredDoc === doc.doc_hash && (
// //                       <div className="col-start-1 row-start-1 justify-self-end self-start pointer-events-none">
// //                         {/* We're not adding a remove button here, but keeping the structure similar */}
// //                       </div>
// //                     )}
// //                   </div>
// //                 </div>
// //               </div>
// //             );
// //           })}
// //         </div>
// //       )}
// //     </div>
// //   );
// // };

// // export default UnmatchedDocs;
// import React, { useState, useMemo } from "react";
// import { ChevronUp, ChevronDown } from "lucide-react";

// const UnmatchedDocs = ({
//   examData,
//   currentTest,
//   handleCellClick,
//   samplesData,
// }) => {
//   const [isOpen, setIsOpen] = useState(true);
//   const [hoveredDoc, setHoveredDoc] = useState(null);

//   // Updated useMemo with new sorting logic
//   const sortedDocs = useMemo(() => {
//     if (!examData || !currentTest || !examData[currentTest]) {
//       return [];
//     }
//     const unmatched_docs = examData[currentTest].unmatched_docs;
//     if (!unmatched_docs || unmatched_docs.length === 0) {
//       return [];
//     }
//     return [...unmatched_docs].sort((a, b) => {
//       // First, sort by doc_type
//       const typeComparison = a.doc_type.localeCompare(b.doc_type);
//       if (typeComparison !== 0) {
//         return typeComparison;
//       }
//       // If doc_types are the same, sort by file_name
//       return a.file_name.localeCompare(b.file_name);
//     });
//   }, [examData, currentTest]);

//   // Helper function to check if a document is in the samples
//   const isDocInSamples = (doc) => {
//     if (!samplesData) return false;
//     return Object.values(samplesData).some((sampleDocs) =>
//       sampleDocs.some(
//         (sampleDoc) =>
//           sampleDoc.file_id === doc.file_id ||
//           sampleDoc.doc_hash === doc.doc_hash
//       )
//     );
//   };

//   const handleDragStart = (e, doc) => {
//     e.dataTransfer.setData(
//       "text/plain",
//       JSON.stringify({
//         currentTest,
//         docType: doc.doc_type,
//         fileName: doc.file_name,
//         docHash: doc.doc_hash,
//         isUnmatched: true,
//         fileId: doc.file_id,
//         image: doc.image,
//       })
//     );
//   };

//   const toggleOpen = () => {
//     setIsOpen(!isOpen);
//   };

//   // If there are no documents to display, return null
//   if (sortedDocs.length === 0) {
//     return null;
//   }

//   return (
//     <div className="m-8">
//       <div className="flex items-center mb-4">
//         <h2 className="text-xl font-semibold mr-2">All Documents</h2>
//         <button onClick={toggleOpen} className="focus:outline-none">
//           {isOpen ? (
//             <ChevronUp className="w-6 h-6" />
//           ) : (
//             <ChevronDown className="w-6 h-6" />
//           )}
//         </button>
//       </div>
//       {isOpen && (
//         <div className="flex flex-wrap -mx-2">
//           {sortedDocs.map((doc, index) => {
//             const isInSamples = isDocInSamples(doc);
//             return (
//               <div
//                 key={index}
//                 className="w-64 px-2 mb-4"
//                 onClick={() => handleCellClick(doc.file_id)}
//                 style={{ cursor: "pointer" }}
//                 draggable
//                 onDragStart={(e) => handleDragStart(e, doc)}
//                 onMouseEnter={() => setHoveredDoc(doc.doc_hash)}
//                 onMouseLeave={() => setHoveredDoc(null)}
//               >
//                 <div
//                   className={`border rounded-lg p-4 h-full flex flex-col ${
//                     isInSamples ? "bg-white" : "bg-gray-100"
//                   }`}
//                 >
//                   <div className="font-medium mb-2">{doc.doc_type}</div>
//                   <div className="text-sm mb-2 flex-grow">{doc.file_name}</div>
//                   <div className="inline-grid grid-cols-1 grid-rows-1 justify-items-center w-full">
//                     <img
//                       src={`data:image/png;base64,${doc.image}`}
//                       alt={doc.file_name}
//                       className="w-48 h-auto object-cover mt-2 col-start-1 row-start-1"
//                     />
//                     {hoveredDoc === doc.doc_hash && (
//                       <div className="col-start-1 row-start-1 justify-self-end self-start pointer-events-none">
//                         {/* We're not adding a remove button here, but keeping the structure similar */}
//                       </div>
//                     )}
//                   </div>
//                 </div>
//               </div>
//             );
//           })}
//         </div>
//       )}
//     </div>
//   );
// };

// export default UnmatchedDocs;
import React, { useState, useMemo } from "react";
import { ChevronUp, ChevronDown } from "lucide-react";

const UnmatchedDocs = ({
  examData,
  currentTest,
  handleCellClick,
  samplesData,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [hoveredDoc, setHoveredDoc] = useState(null);

  // Helper function for natural sort comparison
  const naturalCompare = (a, b) => {
    const splitRegex = /(\d+|\D+)/g;
    const aParts = a.split(splitRegex).filter(Boolean);
    const bParts = b.split(splitRegex).filter(Boolean);

    for (let i = 0; i < Math.min(aParts.length, bParts.length); i++) {
      const aVal = aParts[i],
        bVal = bParts[i];
      const aNum = parseInt(aVal),
        bNum = parseInt(bVal);
      if (!isNaN(aNum) && !isNaN(bNum)) {
        if (aNum !== bNum) return aNum - bNum;
      } else if (aVal !== bVal) {
        return aVal.localeCompare(bVal);
      }
    }
    return aParts.length - bParts.length;
  };

  // Updated useMemo with new sorting logic
  const sortedDocs = useMemo(() => {
    if (!examData || !currentTest || !examData[currentTest]) {
      return [];
    }
    const unmatched_docs = examData[currentTest].unmatched_docs;
    if (!unmatched_docs || unmatched_docs.length === 0) {
      return [];
    }
    return [...unmatched_docs].sort((a, b) => {
      // First, sort by doc_type
      const typeComparison = a.doc_type.localeCompare(b.doc_type);
      if (typeComparison !== 0) {
        return typeComparison;
      }
      // If doc_types are the same, use natural sort on file_name
      return naturalCompare(a.file_name, b.file_name);
    });
  }, [examData, currentTest]);

  // Helper function to check if a document is in the samples
  const isDocInSamples = (doc) => {
    if (!samplesData) return false;
    return Object.values(samplesData).some((sampleDocs) =>
      sampleDocs.some(
        (sampleDoc) =>
          sampleDoc.file_id === doc.file_id ||
          sampleDoc.doc_hash === doc.doc_hash
      )
    );
  };

  const handleDragStart = (e, doc) => {
    e.dataTransfer.setData(
      "text/plain",
      JSON.stringify({
        currentTest,
        docType: doc.doc_type,
        fileName: doc.file_name,
        docHash: doc.doc_hash,
        isUnmatched: true,
        fileId: doc.file_id,
        image: doc.image,
      })
    );
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  // If there are no documents to display, return null
  if (sortedDocs.length === 0) {
    return null;
  }

  return (
    <div className="m-8">
      <div className="flex items-center mb-4">
        <h2 className="text-xl font-semibold mr-2">All Documents</h2>
        <button onClick={toggleOpen} className="focus:outline-none">
          {isOpen ? (
            <ChevronUp className="w-6 h-6" />
          ) : (
            <ChevronDown className="w-6 h-6" />
          )}
        </button>
      </div>
      {isOpen && (
        <div className="flex flex-wrap -mx-2">
          {sortedDocs.map((doc, index) => {
            const isInSamples = isDocInSamples(doc);
            return (
              <div
                key={index}
                className="w-64 px-2 mb-4"
                onClick={() => handleCellClick(doc.file_id)}
                style={{ cursor: "pointer" }}
                draggable
                onDragStart={(e) => handleDragStart(e, doc)}
                onMouseEnter={() => setHoveredDoc(doc.doc_hash)}
                onMouseLeave={() => setHoveredDoc(null)}
              >
                <div
                  className={`border rounded-lg p-4 h-full flex flex-col ${
                    isInSamples ? "bg-white" : "bg-gray-100"
                  }`}
                >
                  <div className="font-medium mb-2">{doc.doc_type}</div>
                  <div className="text-sm mb-2 flex-grow">{doc.file_name}</div>
                  <div className="inline-grid grid-cols-1 grid-rows-1 justify-items-center w-full">
                    <img
                      src={`data:image/png;base64,${doc.image}`}
                      alt={doc.file_name}
                      className="w-48 h-auto object-cover mt-2 col-start-1 row-start-1"
                    />
                    {hoveredDoc === doc.doc_hash && (
                      <div className="col-start-1 row-start-1 justify-self-end self-start pointer-events-none">
                        {/* We're not adding a remove button here, but keeping the structure similar */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default UnmatchedDocs;