// MatchTable.js
import React from 'react';
import CustomTable from './CustomTable';

function MatchTable({ rows, tableName }) {
  const priorityKeys = ["Unique Letter", "Item"];
  const excludedKeys = ["coords", "Expected", "box_texttttttttt"];

  // Filter out excluded keys from the rows
  const filteredRows = rows.map(row => 
    Object.fromEntries(
      Object.entries(row).filter(([key]) => !excludedKeys.includes(key))
    )
  );

  // Get all keys from the first row
  const allKeys = Object.keys(filteredRows[0] || {});

  // Create ordered keys array
  const orderedKeys = [
    ...priorityKeys.filter(key => allKeys.includes(key)),
    ...allKeys.filter(key => !priorityKeys.includes(key) && !excludedKeys.includes(key))
  ];

  const isMergeEnabled = ["Document Comparison", "Document Identification"].some(
    part => tableName.includes(part)
  );

  return (
    <CustomTable
      rows={filteredRows}
      orderedKeys={orderedKeys}
      excludedKeys={[]} // We've already filtered out excluded keys
      mergeEnabled={isMergeEnabled}
      headerMappings={{
        "Unique Letter": "#",
        "Match Value": "Match",
      }}
      formatNumbers={false}
      // Add any additional props for special formatting (bolding, colors, etc.)
      isMatchTable={true}
    />
  );
}

export default MatchTable;