// useModal.js
import { useState, useEffect } from "react";

function useModal() {
  const [isOpen, setIsOpen] = useState(false);
  const [image, setImage] = useState("");
  const [alt, setAlt] = useState("");

  useEffect(() => {
    if (isOpen) {
      const scrollY = window.scrollY;
      document.body.style.position = "fixed";
      document.body.style.top = `-${scrollY}px`;
      document.body.style.width = "100%";
    } else {
      const scrollY = document.body.style.top;
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    }
  }, [isOpen]);

  const openModal = (imageSrc, altText) => {
    setImage(imageSrc);
    setAlt(altText);
    setIsOpen(true);
  };

  const closeModal = () => setIsOpen(false);

  return { isOpen, image, alt, openModal, closeModal };
}

export default useModal;
