import React, { useEffect } from "react";
import Button from "./Button";
import { Pin } from "lucide-react";
import getApiUrl from "../config";

const Sidebar = ({
  buttons,
  isOpen,
  isPinned,
  setIsPinned,
  onMouseEnter,
  onMouseLeave,
}) => {
  const LogoutButton = () => (
    <Button text="Logout" url={getApiUrl("/api/logout")} variant="base" />
  );

  useEffect(() => {
    const pinnedState = localStorage.getItem("sidebarPinned");
    if (pinnedState !== null) {
      setIsPinned(JSON.parse(pinnedState));
    }
  }, [setIsPinned]);

  const handlePinClick = () => {
    const newPinnedState = !isPinned;
    setIsPinned(newPinnedState);
    localStorage.setItem("sidebarPinned", JSON.stringify(newPinnedState));
  };

  const handleButtonClick = (originalOnClick) => (e) => {
    e.stopPropagation(); // Prevent event from bubbling up
    if (!isPinned) {
      onMouseLeave();
    }
    if (originalOnClick) {
      originalOnClick(e);
    }
  };

  return (
    <div
      id="sidebar"
      className={`fixed top-16 left-0 h-[calc(100%-4rem)] w-56 bg-FEBlue shadow-lg transform transition-all duration-300 ease-in-out ${
        isOpen ? "translate-x-0" : "-translate-x-full"
      } ${
        !isPinned && isOpen ? "shadow-[8px_0_10px_-5px_rgba(0,0,0,0.3)]" : ""
      }`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="flex flex-col h-full">
        <nav className="flex-1 flex flex-col gap-4 p-4">
          {buttons.map((button, index) => (
            <div
              key={index}
              className="text-white hover:text-FEOrange transition"
              onClick={handleButtonClick(button.props.onClick)}
            >
              {React.cloneElement(button, {
                onClick: handleButtonClick(button.props.onClick),
              })}
            </div>
          ))}
        </nav>
        <div className="p-4 flex flex-col items-center gap-4">
          <button
            onClick={handlePinClick}
            className={`p-2 rounded-full ${
              isPinned ? "bg-FEOrange" : "bg-gray-600"
            }`}
          >
            <Pin size={20} color="white" />
          </button>
          <LogoutButton />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
