import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { X } from "lucide-react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import Header from "./components/Header";
import getApiUrl from "./config";

const SampleUploadPage = () => {
  const { examId } = useParams();
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isSelecting, setIsSelecting] = useState(false);
  const [message, setMessage] = useState("");
  const [fileUploaded, setFileUploaded] = useState(false);
  const [query, setQuery] = useState("");
  const [queryResults, setQueryResults] = useState([]);
  const [querySteps, setQuerySteps] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [checkedState, setCheckedState] = useState([]);

  useEffect(() => {
    checkFileUpload();
  }, [examId]);

  const checkFileUpload = async () => {
    try {
      const response = await fetch(getApiUrl("/api/check_sample_upload"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ exam_id: examId }),
      });
      const data = await response.json();
      setFileUploaded(data.file_found);
    } catch (error) {
      console.error("Error checking file upload:", error);
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();
    if (!file) {
      setMessage("Please select a file first.");
      return;
    }
    setIsUploading(true);
    setMessage("");

    const formData = new FormData();
    formData.append("file", file);
    formData.append("exam_id", examId);

    try {
      console.log("TRYING TO UPLOAD FILE TO", getApiUrl("/api/sample_upload"));
      console.log("FORM DATA", formData);
      const response = await fetch(getApiUrl("/api/sample_upload"), {
        method: "POST",
        body: formData,
      });
      console.log("RESPONSE", response);
      const data = await response.json();
      if (data.status === "success") {
        setMessage("File uploaded successfully");
        setFileUploaded(true);
      } else {
        setMessage("File upload failed");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setMessage("An error occurred while uploading the file.");
    } finally {
      setIsUploading(false);
    }
  };

  const handleQuerySubmit = async (e) => {
    e.preventDefault();
    setIsSelecting(true);
    try {
      const response = await fetch(getApiUrl("/api/run_query"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ exam_id: examId, query: query }),
      });
      const data = await response.json();
      if (data.status === "success") {
        setQueryResults(data.result);
        setQuerySteps(data.steps);

        // Create a new checked state array, setting true for rows that are in selectedRows
        const newCheckedState = data.result.map((row) =>
          selectedRows.some((selectedRow) => isEqual(selectedRow, row))
        );
        setCheckedState(newCheckedState);

        setMessage("Query executed successfully");
      } else {
        setMessage("Query execution failed");
      }
    } catch (error) {
      console.error("Error running query:", error);
      setMessage("An error occurred while running the query.");
    } finally {
      setIsSelecting(false);
    }
  };


  const handleRowSelection = (index) => {
    const row = queryResults[index];
    if (!row) return;

    setCheckedState((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });

    setSelectedRows((prevSelected) => {
      if (prevSelected.some((r) => isEqual(r, row))) {
        return prevSelected.filter((r) => !isEqual(r, row));
      } else {
        return [...prevSelected, row];
      }
    });
  };


  const handleRemoveSelectedRow = (rowToRemove) => {
    setSelectedRows((prevSelected) =>
      prevSelected.filter((r) => !isEqual(r, rowToRemove))
    );

    const index = queryResults.findIndex((r) => isEqual(r, rowToRemove));
    if (index !== -1) {
      setCheckedState((prevState) => {
        const newState = [...prevState];
        newState[index] = false;
        return newState;
      });
    }
  };

  const handleSelectAll = () => {
    const allCurrentlySelected = checkedState.every(Boolean);

    if (allCurrentlySelected) {
      // If all current results are selected, unselect only the current results
      setCheckedState(new Array(queryResults.length).fill(false));
      setSelectedRows((prevSelected) =>
        prevSelected.filter(
          (row) => !queryResults.some((queryRow) => isEqual(queryRow, row))
        )
      );
    } else {
      // If not all are selected, select all current results
      setCheckedState(new Array(queryResults.length).fill(true));
      setSelectedRows((prevSelected) => {
        const newSelected = [...prevSelected];
        queryResults.forEach((row) => {
          if (!newSelected.some((selectedRow) => isEqual(selectedRow, row))) {
            newSelected.push(row);
          }
        });
        return newSelected;
      });
    }
  };

  const isEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  const handleDownloadSelected = useCallback(() => {
    if (selectedRows.length > 0) {
      const headers = Object.keys(selectedRows[0]);
      const data = selectedRows.map((row) =>
        headers.map((header) => String(row[header]))
      );
      const wsData = [headers, ...data];

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(wsData);
      XLSX.utils.book_append_sheet(wb, ws, "SelectedSamples");

      const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const blob = new Blob([wbout], { type: "application/octet-stream" });
      saveAs(blob, `selected_samples_${examId}.xlsx`);
    } else {
      console.log("No rows selected for download.");
    }
  }, [selectedRows, examId]);

  const StepsList = ({ steps }) => {
    if (!steps || steps.length === 0) return null;

    return (
      <div>
        <h3 className="text-lg font-medium text-gray-900 mb-2">
          Selection Steps:
        </h3>
        <ol className="list-decimal list-inside">
          {steps.map((step, index) => (
            <li key={index} className="text-gray-700 mb-1">
              {step}
            </li>
          ))}
        </ol>
      </div>
    );
  };

  const ResultsTable = ({ results, onRowSelect, checkedState }) => {
    if (!results || results.length === 0) {
      return null;
    }

    const headers = Object.keys(results[0]);

    return (
      <div>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="p-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r"
                >
                  Select
                </th>
                {headers.map((header, index) => (
                  <th
                    key={header}
                    scope="col"
                    className={`p-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${
                      index !== headers.length - 1 ? "border-r" : ""
                    }`}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {results.map((row, rowIndex) => (
                <tr key={row.id}>
                  <td className="p-2 whitespace-nowrap border-r">
                    <input
                      type="checkbox"
                      className="form-checkbox h-4 w-4 text-blue-600"
                      onChange={() => onRowSelect(rowIndex)}
                      checked={checkedState[rowIndex] || false}
                    />
                  </td>
                  {headers.map((header, colIndex) => (
                    <td
                      key={header}
                      className={`p-2 whitespace-nowrap ${
                        colIndex !== headers.length - 1 ? "border-r" : ""
                      }`}
                    >
                      {row[header]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="bg-gray-100 px-4 py-3 border-t border-gray-200">
          <div className="flex justify-start">
            <button
              onClick={handleSelectAll}
              className="inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              {checkedState.every(Boolean) ? "Deselect All" : "Select All"}
            </button>
          </div>
        </div>
      </div>
    );
  };

  const SelectedRowsTable = ({ rows, onRemove }) => {
    if (!rows || rows.length === 0) return null;

    const headers = Object.keys(rows[0]).filter((key) => key !== "id");

    return (
      <div className="flex flex-col bg-gray-50 rounded-lg shadow-md overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr>
                {headers.map((header, index) => (
                  <th
                    key={header}
                    scope="col"
                    className={`p-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${
                      index !== headers.length - 1
                        ? "border-r border-gray-200"
                        : ""
                    }`}
                  >
                    {header}
                  </th>
                ))}
                <th scope="col" className="relative p-3">
                  <span className="sr-only">Remove</span>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {rows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {headers.map((header, index) => (
                    <td
                      key={header}
                      className={`p-3 whitespace-nowrap text-sm ${
                        index !== headers.length - 1
                          ? "border-r border-gray-200"
                          : ""
                      }`}
                    >
                      {row[header]}
                    </td>
                  ))}
                  <td className="p-3 whitespace-nowrap text-right text-sm font-medium">
                    <button
                      onClick={() => onRemove(row)}
                      className="text-red-600 hover:text-red-900"
                    >
                      <X size={16} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="bg-gray-100 px-4 py-3 border-t border-gray-200">
          <div className="flex justify-end">
            <button
              onClick={handleDownloadSelected}
              className="inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              Download
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Header buttons={[]} />
      <div className="flex-grow bg-white mt-16">
        <div className="p-4 sm:p-6 md:p-8 h-full">
          <div className="flex flex-col lg:flex-row lg:space-x-8 h-full">
            <div className="lg:w-1/2 space-y-6">
              <div className="bg-gray-50 p-6 rounded-lg border border-gray-200">
                <p className="text-lg font-medium text-gray-700 mb-4">
                  Upload sample selection file
                </p>
                <p className="text-sm font-medium text-gray-500 mb-4">
                  Detailed AR aging, cash receipts journal, etc.
                </p>
                <p className="text-sm font-medium text-gray-500 mb-4">
                  PDF or Excel format
                </p>

                <form onSubmit={handleFileUpload} className="space-y-4">
                  <div>
                    <input
                      id="file-upload"
                      type="file"
                      onChange={handleFileChange}
                      accept=".pdf,.xlsx,.csv"
                      className="mt-1 block w-full text-sm text-gray-500
                      file:mr-4 file:py-2 file:px-4
                      file:rounded-full file:border-0
                      file:text-sm file:font-semibold
                      file:bg-blue-50 file:text-blue-700
                      hover:file:bg-blue-100"
                    />
                  </div>
                  <div className="flex justify-start">
                    <button
                      type="submit"
                      disabled={isUploading || !file}
                      className="inline-flex justify-center py-2 px-8 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-gray-400 disabled:cursor-not-allowed"
                    >
                      {isUploading ? "Uploading..." : "Upload"}
                    </button>
                  </div>
                </form>
              </div>

              {fileUploaded && (
                <div className="bg-gray-50 p-6 rounded-lg border border-gray-200">
                  <p className="text-lg font-medium text-gray-700 mb-4">
                    File uploaded!
                    <br />
                    Enter your sample selection query below.
                  </p>
                  <form onSubmit={handleQuerySubmit} className="space-y-4">
                    <div>
                      <input
                        type="text"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        className="mt-1 block w-full rounded-md border-gray-500 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2"
                        placeholder="What samples would you like to select?"
                      />
                    </div>
                    <div className="flex justify-start">
                      <button
                        type="submit"
                        disabled={isSelecting || !query.trim()}
                        className="inline-flex justify-center py-2 px-8 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-gray-400 disabled:cursor-not-allowed"
                      >
                        {isSelecting ? "Selecting..." : "Select Samples"}
                      </button>
                    </div>
                  </form>
                </div>
              )}

              {querySteps && querySteps.length > 0 && (
                <div className="bg-gray-50 p-6 rounded-lg border border-gray-200">
                  <StepsList steps={querySteps} />
                </div>
              )}

              {queryResults && queryResults.length > 0 && (
                <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
                  <ResultsTable
                    results={queryResults}
                    onRowSelect={handleRowSelection}
                    checkedState={checkedState}
                  />
                </div>
              )}
            </div>

            <div className="lg:w-1/2 mt-8 lg:mt-0">
              {selectedRows && selectedRows.length > 0 && (
                <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
                  <SelectedRowsTable
                    rows={selectedRows}
                    onRemove={handleRemoveSelectedRow}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SampleUploadPage;
