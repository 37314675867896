import { useState, useEffect, useCallback } from "react";
import getApiUrl from "../config";

const useCollaborators = (examId) => {
  const [collaborators, setCollaborators] = useState([]);

  useEffect(() => {
    const fetchCollaborators = async () => {
      try {
        const response = await fetch(
          getApiUrl(`/api/get_exam_outside_collaborators/${examId}`),
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }
        );
        const collaboratorsData = await response.json();
        setCollaborators(collaboratorsData.emails);
      } catch (error) {
        console.error("Error fetching collaborators:", error);
      }
    };

    fetchCollaborators();
  }, [examId]);

  const addCollaborators = useCallback(
    async (newEmails) => {
      try {
        await fetch(
          getApiUrl(`/api/add_exam_outside_collaborators/${examId}`),
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ emails: newEmails }),
          }
        );
        setCollaborators((prevCollaborators) => [
          ...prevCollaborators,
          ...newEmails,
        ]);
      } catch (error) {
        console.error("Error adding collaborators:", error);
      }
    },
    [examId]
  );

  const removeCollaborator = useCallback(
    async (email) => {
      if (window.confirm(`Are you sure you want to remove ${email}?`)) {
        try {
          await fetch(
            getApiUrl(`/api/remove_exam_outside_collaborators/${examId}`),
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ emails: [email] }),
            }
          );
          setCollaborators((prevCollaborators) =>
            prevCollaborators.filter((collaborator) => collaborator !== email)
          );
        } catch (error) {
          console.error("Error removing collaborator:", error);
        }
      }
    },
    [examId]
  );

  return { collaborators, addCollaborators, removeCollaborator };
};

export default useCollaborators;
