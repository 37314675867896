import React, { useState, useEffect, useCallback } from "react";
import { ChevronUp, ChevronDown } from "lucide-react";
import getApiUrl from "../config";

const SampleRequestSection = ({ examId, currentTest }) => {
  const [tableData, setTableData] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const excludedTests = ["ar_ap_analysis"];

  useEffect(() => {
    const fetchSampleRequestTable = async () => {
      if (examId && currentTest) {
        try {
          const response = await fetch(
            getApiUrl("/api/get_sample_request_table"),
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                exam_id: examId,
                test_name: currentTest,
              }),
            }
          );
          if (response.ok) {
            const data = await response.json();
            if (data.status === "success" && data.request_table) {
              setTableData(data.request_table);
            } else {
              setTableData(null); // Clear the table data if no data is available for the current test
            }
          } else {
            console.error("Failed to fetch sample request table");
            setTableData(null);
          }
        } catch (error) {
          console.error("Error fetching sample request table:", error);
          setTableData(null);
        }
      }
    };

    fetchSampleRequestTable();
  }, [examId, currentTest]);

  const handleFileUpload = useCallback(
    async (event) => {
      const file = event.target.files[0];
      if (!file) return;

      setIsUploading(true);
      const formData = new FormData();
      formData.append("file", file);

      const jsonData = JSON.stringify({
        exam_id: examId,
        test_name: currentTest,
      });

      try {
        console.log("TRYING TO UPLOAD TO", getApiUrl("/api/upload_sample_request"));
        const response = await fetch(getApiUrl("/api/upload_sample_request"), {
          method: "POST",
          body: formData,
          headers: {
            "X-Data": jsonData,
          },
        });

        if (response.ok) {
          const result = await response.json();
          console.log("File uploaded successfully");
          console.log("Uploaded data:", result.request_table);
          setTableData(result.request_table);
        } else {
          console.error("File upload failed");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setIsUploading(false);
      }
    },
    [examId, currentTest]
  );

  const renderTable = (data) => {
    if (!data || data.length === 0) return null;

    const headers = Object.keys(data[0]);

    return (
      <div className="mt-4 overflow-x-auto rounded-lg border border-gray-200">
        <table className="min-w-full divide-y divide-gray-200 bg-white text-sm">
          <thead className="bg-gray-50">
            <tr>
              {headers.map((header) => (
                <th
                  key={header}
                  className="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {data.map((row, index) => (
              <tr key={index}>
                {headers.map((header) => (
                  <td
                    key={`${index}-${header}`}
                    className="px-4 py-2 whitespace-nowrap text-gray-700 border-r"
                  >
                    {row[header]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  if (excludedTests.includes(currentTest)) {
    return null;
  }
  return (
    <div className="sample-request-section m-8">
      <div className="flex items-center mb-4">
        <h2 className="text-xl font-semibold mr-2">Sample Request</h2>
        <button onClick={toggleOpen} className="focus:outline-none">
          {isOpen ? (
            <ChevronUp className="w-6 h-6" />
          ) : (
            <ChevronDown className="w-6 h-6" />
          )}
        </button>
      </div>
      {isOpen && (
        <>
          <div className="mb-4">
            <input
              type="file"
              onChange={handleFileUpload}
              style={{ display: "none" }}
              id="fileInput"
              disabled={isUploading}
            />
            <label
              htmlFor="fileInput"
              className={`bg-FEOrange hover:bg-FEOrange-dark text-white font-bold py-2 px-4 rounded cursor-pointer ${
                isUploading ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {isUploading ? "Uploading..." : `Upload Sample Request`}
            </label>
          </div>
          {tableData ? (
            renderTable(tableData)
          ) : (
            <p>Upload {currentTest} sample request to organize samples.</p>
          )}
        </>
      )}
    </div>
  );
};

export default SampleRequestSection;
