

import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import getApiUrl from "../config";

const ExamItem = ({
  exam,
  handleManageClick,
  onArchive,
  isArchivePage = false,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState("");
  const menuRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleArchive = async () => {
    try {
      const response = await fetch(getApiUrl("/api/archive_exam"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ exam_id: exam.exam_id }),
      });
      if (response.ok) {
        const result = await response.json();
        if (result.status === "success") {
          onArchive(exam.exam_id);
        }
      } else {
        console.error("Error archiving exam:", response.statusText);
      }
    } catch (error) {
      console.error("Error archiving exam:", error);
    }
    setIsMenuOpen(false);
  };

  const handleRestore = async () => {
    try {
      const response = await fetch(getApiUrl("/api/unarchive_exam"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ exam_id: exam.exam_id }),
      });
      if (response.ok) {
        const result = await response.json();
        if (result.status === "success") {
          onArchive(exam.exam_id);
        }
      } else {
        console.error("Error restoring exam:", response.statusText);
      }
    } catch (error) {
      console.error("Error restoring exam:", error);
    }
  };

  const handleSampleSelect = () => {
    navigate(`/sample-select/${exam.exam_id}`);
  };

  const handleDeleteClick = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (deleteConfirmation === `DELETE ${exam.exam_name}`) {
      try {
        const response = await fetch(getApiUrl("/api/delete_exam"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ exam_id: exam.exam_id }),
        });
        if (response.ok) {
          onArchive(exam.exam_id); // Remove the exam from the list
        } else {
          console.error("Error deleting exam:", response.statusText);
        }
      } catch (error) {
        console.error("Error deleting exam:", error);
      }
      setIsDeleteDialogOpen(false);
    }
  };

  const isDeleteConfirmed = deleteConfirmation === `DELETE ${exam.exam_name}`;

  return (
    <div className="relative flex flex-col gap-4 p-4 border rounded shadow-md">
      {!isArchivePage && (
        <div className="absolute top-2 right-2">
          <div className="relative" ref={menuRef}>
            <button
              onClick={toggleMenu}
              className="p-1 text-gray-400 hover:bg-gray-50 hover:text-gray-600 rounded-full"
            >
              <span className="sr-only">Menu</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
              </svg>
            </button>
            {isMenuOpen && (
              <div
                className="absolute right-0 z-10 mt-2 w-48 divide-y divide-gray-100 rounded-md border border-gray-100 bg-white shadow-lg"
                role="menu"
              >
                <div className="p-2">
                  <button
                    className="block w-full text-left rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
                    role="menuitem"
                    onClick={handleArchive}
                  >
                    Archive Exam
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <h3 className="text-2xl font-bold">{exam.exam_name}</h3>
      <p className="text-sm">Files Uploaded: {exam.files_count}</p>
      <p className="text-sm">
        Invoice Test Samples: {exam.invoice_samples_count}
      </p>
      <p className="text-sm">
        Cash Application Test Samples: {exam.cash_samples_count}
      </p>
      <p className="text-sm">
        Vendor Payment Test Samples: {exam.vendor_samples_count}
      </p>
      <p className="text-sm">Aging Samples: {exam.aging_samples_count}</p>
      <div className="flex justify-between mt-2 space-x-2">
        {isArchivePage && (
          <button
            className="px-4 py-2 rounded-md font-semibold text-sm text-white bg-red-600 hover:bg-red-700"
            onClick={handleDeleteClick}
          >
            Delete
          </button>
        )}
        {isArchivePage ? (
          <button
            className="px-4 py-2 rounded-md font-semibold text-sm bg-blue-600 text-white hover:bg-blue-700"
            onClick={handleRestore}
          >
            Restore
          </button>
        ) : (
          <>
            <button
              className="px-4 py-2 rounded-md font-semibold text-sm border border-gray-300 text-gray-700 hover:bg-gray-50"
              onClick={handleSampleSelect}
            >
              Sample Select
            </button>
            <button
              className="px-4 py-2 rounded-md font-semibold text-sm bg-blue-600 text-white hover:bg-blue-700"
              onClick={() => handleManageClick(exam.exam_id)}
            >
              Manage
            </button>
          </>
        )}
      </div>
      {isDeleteDialogOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg">
            <h2 className="text-xl font-bold mb-4">Delete Exam</h2>
            <p>Type 'DELETE {exam.exam_name}' below to delete the exam and all of its data. This cannot be undone.</p>
            <input
              type="text"
              value={deleteConfirmation}
              onChange={(e) => setDeleteConfirmation(e.target.value)}
              placeholder={`DELETE ${exam.exam_name}`}
              className="w-full border rounded p-2 my-4"
            />
            <div className="flex justify-end space-x-2">
              <button
                className="px-4 py-2 rounded-md font-semibold text-sm border border-gray-300 text-gray-700 hover:bg-gray-50"
                onClick={() => setIsDeleteDialogOpen(false)}
              >
                Cancel
              </button>
              <button
                className={`px-4 py-2 rounded-md font-semibold text-sm ${
                  isDeleteConfirmed 
                    ? 'text-white bg-red-600 hover:bg-red-700 cursor-pointer' 
                    : 'text-gray-400 bg-gray-200 cursor-not-allowed'
                }`}
                onClick={handleDeleteConfirm}
                disabled={!isDeleteConfirmed}
              >
                DELETE
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExamItem;